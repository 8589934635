import React from 'react';
import LegalPage from './LegalPage';

const LegalPageConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/eula',
			exact: true,
			component: () => <LegalPage type="eula" />
		},
		{
			path: '/terms',
			exact: true,
			component: () => <LegalPage type="terms" />
		},
		{
			path: '/privacy',
			exact: true,
			component: () => <LegalPage type="privacy" />
		}
	]
};

export default LegalPageConfig;
