import axios from 'app/client';
import { responseError, getGenerationByFamily, getDeviceStatus } from 'app/utils/helpers';
import { parseJsonHack, reverseParseJsonHack } from 'app/utils/hacks';
import {
	getSelectedLicenseGroupId,
	getSelectedLicenseGroupVersion,
	getSelectedLicenseGroupData as getSelectedLicenseGroupDataSelector
} from 'app/store/reducers';
import { AppThunk } from 'app/store';
import {
	LicenseGroupData,
	PublicId,
	User,
	PendingUser,
	Device,
	DeviceGroup,
	Log,
	Policy,
	ScheduledReport
} from 'app/store/types';
import _ from '@lodash';
import * as appActions from './app.actions';

// HACK::allow switching license group plan for testing
const mockSelectedLicenseGroupPlan = localStorage.getItem('mockPlan') as PublicId | null;

// HACK::allow switching order type for testing
const mockSelectedLicenseGroupOrderType = localStorage.getItem('mockOrderType') as LicenseGroupData['orderType'] | null;

// HACK::allow switching to expired license for testing
const mockExpired = localStorage.getItem('mockExpired');

// HACK::reshape data returned from backend
const massageRawLicenseGroup = (rawLicenseGroup: any) => {
	const licenseGroupData: LicenseGroupData = {
		id: rawLicenseGroup.SK,
		name: rawLicenseGroup.name,
		ownerId: rawLicenseGroup.licenseOwnerId,
		quantity: rawLicenseGroup.license.quantity,
		capacity: rawLicenseGroup.license.capacity,
		version: rawLicenseGroup.license.ver,
		createdAt: rawLicenseGroup.createdAt ?? undefined,
		currentUsers: rawLicenseGroup.currentUsers ?? undefined,
		currentUserLostVaultKey: rawLicenseGroup.currentUserLostVaultKey ?? undefined,
		expirationDate:
			rawLicenseGroup.expirationDate || mockExpired
				? +new Date(mockExpired ?? rawLicenseGroup.expirationDate)
				: undefined,
		// HACK::publicId can come in upper- or lower-case
		catalogPublicId: mockSelectedLicenseGroupPlan ?? rawLicenseGroup.catalogPublicId.toUpperCase(),
		permissions: (rawLicenseGroup.permissions as string[]).reduce(
			(acc, permission) => ({ ...acc, [permission]: true }),
			{}
		),
		// HACK::these keys *should* always be upper-case but are sometimes title-case
		orderType: mockSelectedLicenseGroupOrderType ?? rawLicenseGroup.licenseType.toUpperCase(),
		orderNumber: rawLicenseGroup.orderNumber,
		isSelfManagedRequired: rawLicenseGroup.isSelfManagedRequired,
		tenantUpdatedAt: rawLicenseGroup.tenantUpdatedAt
	};

	return licenseGroupData;
};

const massageRawUser = (rawUser: any) => {
	const user: User = {
		id: rawUser.SK,
		email: rawUser.email,
		firstName: rawUser.firstName,
		lastName: rawUser.lastName,
		dateAdded: rawUser.createdAt,
		vaultType: rawUser.vaultType,
		vaultReset: rawUser.vaultReset,
		permissions: rawUser.permissions
	};

	return user;
};

const massageRawPendingUser = (rawPendingUser: any) => {
	const pendingUser: PendingUser = {
		email: rawPendingUser.SK,
		token: rawPendingUser.token,
		dateAdded: rawPendingUser.dateAdded,
		// add `pending` prop for distinguishing if this list gets mixed with regular users
		pending: true
	};

	return pendingUser;
};

const massageRawDevice = (rawDevice: any) => {
	const deviceStatus = getDeviceStatus({
		policyId: rawDevice.policyId,
		lastAssessment: rawDevice.assessmentTs,
		secureStatus: rawDevice.secureStatus,
		pending: false, // NOTE::no way of knowing a device has the Shield Agent installed ATM (but also a device without the agent can't be imported so this will never be true)
		offline: !!rawDevice.policyId && rawDevice.onlineStatus === false
	});

	const device: Device = {
		serial: rawDevice.SK,
		serialNumber: rawDevice.deviceInfo.serialNumber,
		name: rawDevice.name,
		generation: getGenerationByFamily(rawDevice.deviceInfo.deviceType.family),
		active: rawDevice.active,
		friendlyName: rawDevice.friendlyName,
		localIp: rawDevice.localIp,
		dateAdded: rawDevice.createdAt,
		lastAssessment: rawDevice.assessmentTs,
		deviceGroupId: rawDevice.deviceGroupId,
		policyId: rawDevice.policyId,
		secureStatus: rawDevice.secureStatus,
		pending: false, // NOTE::no way of knowing a device has the Shield Agent installed ATM (but also a device without the agent can't be imported so this will never be true)
		offline: !!rawDevice.policyId && rawDevice.onlineStatus === false, // must have a policy assigned to be "offline" (is this logical?)
		lastOnlineStatusChange: rawDevice.onlineStatusTs,
		deviceStatus,
		lastSecure:
			deviceStatus === 'offline'
				? rawDevice.onlineStatusTs
				: deviceStatus === 'notSecure'
				? rawDevice.assessmentTs
				: undefined,
		policyFails: rawDevice.policyFails,
		publicKey: rawDevice.publicKey
	};

	return device;
};
const massageRawDeviceGroup = (rawDeviceGroup: any) => {
	const deviceGroup: DeviceGroup = {
		id: rawDeviceGroup.SK,
		name: rawDeviceGroup.name,
		policyId: rawDeviceGroup.policyId,
		dateUpdated: rawDeviceGroup.updatedAt
	};

	return deviceGroup;
};

const massageRawLog = (rawLog: any) => {
	const log: Log = {
		id: rawLog.SK,
		type: rawLog.type,
		dateCreated: parseInt(rawLog.createdAt, 10),
		severity: rawLog.severity,
		messageKey: rawLog.msgKey,
		info: rawLog.info
	};

	return log;
};

const massageRawScheduledReport = (rawScheduledReport: any) => {
	const scheduledReport: ScheduledReport = {
		id: rawScheduledReport.SK,
		name: rawScheduledReport.name,
		format: rawScheduledReport.format,
		frequency: rawScheduledReport.frequency,
		reportInfo: rawScheduledReport.reportInfo,
		lastModified: rawScheduledReport.updatedAt
	};

	return scheduledReport;
};

const massageRawPolicy = (rawPolicy: any) => {
	// TEMP HACKS (HOPEFULLY)
	// HACK::reshape some falsy values that mean the same thing
	const policyItems: Policy['policyItems'] = rawPolicy.policyItems
		.map((policyItem: any) => ({ ...policyItem, remediation: policyItem.remediation ?? false })) // default any old policy items to `false` from before remediation was added
		.filter((policyItem: any) => !['na', -1].includes(policyItem.requirement))
		.map((policyItem: any) => {
			policyItem.options = policyItem.options.filter(({ val }: any) => !['na', false, -1].includes(val));
			return policyItem;
		});

	const policy: Policy = {
		id: rawPolicy.SK,
		name: rawPolicy.policyName,
		policyItems,
		pingInterval: rawPolicy.timing.pingInterval,
		telemetryCheckInterval: rawPolicy.timing.telemetryCheckInterval,
		offlineThreshold: rawPolicy.timing.offlineThreshold,
		lastModified: rawPolicy.policyTs,
		passwordConfig: {
			...(rawPolicy.passwordConfig ?? {}),
			randomPasswordOptions: {
				...(rawPolicy.passwordConfig?.randomPasswordOptions ?? {})
			},
			manualPasswordOptions: {
				...(rawPolicy.passwordConfig?.manualPasswordOptions ?? {})
			}
		}
	};

	return policy;
};

// at some point this may be broken into more specific API calls

export const getLicenseGroups = (): AppThunk => async dispatch => {
	try {
		const { data: rawLicenseGroups } = await axios.get('/api/v1/tenant');

		// HACK::apply various hacks
		const licenseGroups = parseJsonHack(rawLicenseGroups).map(massageRawLicenseGroup);

		dispatch({
			type: 'GET_LICENSE_GROUPS_SUCCESS',
			payload: {
				data: _.keyBy(licenseGroups, 'id')
			}
		});
	} catch (error) {
		if ((error as any).response?.status === 401) {
			// if user not logged in
			dispatch({
				type: 'GET_LICENSE_GROUPS_SUCCESS',
				payload: {
					data: undefined
				}
			});
			return;
		}
		dispatch(appActions.handleError(error));
		// re-throw error for handling in <InitializeApp />
		throw error;
	}
};

export const getSelectedLicenseGroupData = (): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const [
			{ data: rawLicenseGroup },
			{ data: rawUsers },
			// { data: rawPendingUsers },
			{ data: rawPolicies },
			{ data: rawDevices },
			{ data: rawDeviceGroups },
			{ data: rawLogs },
			{ data: rawScheduledReports }
		] = await Promise.all([
			axios.get(`/api/v1/tenant/${licenseGroupId}`),
			axios.get(`/api/v1/user/${licenseGroupId}`),
			// axios.get(`/api/v1/pending-user/${licenseGroupId}`),
			axios.get(`/api/v1/policy/${licenseGroupId}`),
			axios.get(`/api/v1/device/${licenseGroupId}`),
			axios.get(`/api/v1/devicegroup/${licenseGroupId}`),
			axios.get(`/api/v1/log/${licenseGroupId}`),
			axios.get(`/api/v1/report/${licenseGroupId}`)
		]);

		// HACK::apply various hacks
		const licenseGroup: LicenseGroupData = massageRawLicenseGroup(parseJsonHack(rawLicenseGroup));
		const users: User[] = parseJsonHack(rawUsers).map(massageRawUser);
		const policies: Policy[] = parseJsonHack(rawPolicies).map(massageRawPolicy);
		// const pendingUsers: PendingUser[] = parseJsonHack(rawPendingUsers).map(massageRawPendingUser);
		const pendingUsers: PendingUser[] = [].map(massageRawPendingUser); // TEMP::no pendingUsers API ATM
		const devices: Device[] = parseJsonHack(rawDevices).map(massageRawDevice);
		const deviceGroups: DeviceGroup[] = parseJsonHack(rawDeviceGroups).map(massageRawDeviceGroup);
		const logs: Log[] = parseJsonHack(rawLogs).map(massageRawLog);
		const scheduledReports: ScheduledReport[] = rawScheduledReports.map(massageRawScheduledReport);

		dispatch({
			type: 'GET_LICENSE_GROUP_DATA_SUCCESS',
			payload: {
				licenseGroupId,
				data: {
					details: licenseGroup,
					users: _.keyBy(users, 'id'),
					pendingUsers: _.keyBy(pendingUsers, 'email'),
					devices: _.keyBy(devices, 'serial'),
					deviceGroups: _.keyBy(deviceGroups, 'id'),
					policies: _.keyBy(policies, 'id'),
					logs: _.keyBy(logs, 'id'),
					scheduledReports: _.keyBy(scheduledReports, 'id')
				}
			}
		});
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const editSelectedLicenseGroup =
	({ name }: { name: string }): AppThunk =>
	async (dispatch, getState) => {
		const licenseGroupId = getSelectedLicenseGroupId(getState());

		const data = reverseParseJsonHack({
			name
		});

		try {
			await axios.patch(`/api/v1/tenant/${licenseGroupId}`, data);
			dispatch(appActions.alert('settings updated', 'success'));
			dispatch(getSelectedLicenseGroupData());
		} catch (error) {
			dispatch(appActions.handleError(error));
		}
	};

export const editSelectedLicenseGroupIsSelfManagedRequired =
	(isSelfManagedRequired: boolean): AppThunk =>
	async (dispatch, getState) => {
		const licenseGroupId = getSelectedLicenseGroupId(getState());

		const data = reverseParseJsonHack({
			isSelfManagedRequired
		});

		try {
			await axios.patch(`/api/v1/tenant/${licenseGroupId}`, data);
			dispatch(appActions.alert('settings updated', 'success'));
			dispatch(getSelectedLicenseGroupData());
		} catch (error) {
			dispatch(appActions.alert('failed to update settings', 'warning'));
			throw error;
		}
	};

export const modifySelectedLicenseGroup =
	({ newPublicId, newCapacity }: { newPublicId?: PublicId; newCapacity: number }): AppThunk =>
	async (dispatch, getState) => {
		const licenseGroupId = getSelectedLicenseGroupId(getState());
		const licenseGroupVersion = getSelectedLicenseGroupVersion(getState());

		try {
			await axios.patch(`/api/v1/tenant/${licenseGroupId}/registration`, {
				version: licenseGroupVersion,
				capacity: newCapacity,
				...(newPublicId && { catalogItemId: newPublicId })
			});
			dispatch(appActions.alert('license plan updated', 'success'));
			dispatch(getSelectedLicenseGroupData());
		} catch (error) {
			dispatch(appActions.handleError(error));
		}
	};

export const updateSelectedLicenseGroup =
	({ purchaseCode }: { purchaseCode: string }): AppThunk =>
	async (dispatch, getState) => {
		const licenseGroupId = getSelectedLicenseGroupId(getState());
		const { orderNumber } = getSelectedLicenseGroupDataSelector(getState());
		purchaseCode = purchaseCode.replace(/-/g, '');

		try {
			const response = await axios.patch(`/api/v1/tenant/${licenseGroupId}/update`, {
				purchaseCode,
				orderNumber
			});
			console.log(response);
			if (response.data.upgraded === true) {
				dispatch(appActions.alert('license modified', 'success'));
				dispatch(getSelectedLicenseGroupData());
			} else {
				dispatch(appActions.alert('license plan failed', 'warning'));
			}
		} catch (error) {
			dispatch(appActions.handleError(error));
		}
	};

export const getTermLicense =
	(purchaseCode: string, successFn?: Function, failFn?: Function): AppThunk =>
	async (dispatch, getState) => {
		purchaseCode = purchaseCode.replace(/-/g, '');

		try {
			const response = await axios.post(`/api/v1/tenant/purchase`, {
				purchaseCode
			});
			console.log(response);
			// @ts-ignore
			if (response.data.order) {
				// dispatch(appActions.alert('License upgraded', 'success'));
				dispatch(getSelectedLicenseGroupData());
				// @ts-ignore
				if (successFn) successFn(response.data.order);
			} else {
				dispatch(appActions.alert('Purchase code invalid', 'error'));
				if (failFn) failFn();
			}
		} catch (error) {
			dispatch(appActions.alert('Purchase code invalid', 'error'));
			if (failFn) failFn();
		}
	};
