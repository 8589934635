import FuseSearch from '@fuse/core/FuseSearch';
import FuseShortcuts from '@fuse/core/FuseShortcuts';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import NavbarMobileToggleButton from 'app/fuse-layouts/shared-components/NavbarMobileToggleButton';
import QuickPanelToggleButton from 'app/fuse-layouts/shared-components/quickPanel/QuickPanelToggleButton';
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu';
import React from 'react';
import { useSelector } from 'app/modules/react-redux';
import LanguageSwitcher from '../../shared-components/LanguageSwitcher';

const useStyles = makeStyles(theme => ({
	separator: {
		width: 1,
		height: 64,
		backgroundColor: theme.palette.divider
	}
}));

function ToolbarLayout2(props) {
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const toolbarTheme = useSelector(({ fuse }) => fuse.settings.toolbarTheme);

	const classes = useStyles(props);

	return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={toolbarTheme}>
                <AppBar
                    id="fuse-toolbar"
                    className="flex relative z-10"
                    color="default"
                    style={{ backgroundColor: toolbarTheme.palette.background.default }}
                >
                    <Toolbar className="container p-0 lg:px-24">
                        {config.navbar.display && (
                            <Hidden lgUp>
                                <NavbarMobileToggleButton className="w-64 h-64 p-0" />
                                <div className={classes.separator} />
                            </Hidden>
                        )}

                        <div className="flex flex-1">
                            <Hidden xlDown>
                                <FuseShortcuts />
                            </Hidden>
                        </div>

                        <div className="flex">
                            <UserMenu />

                            <div className={classes.separator} />

                            <FuseSearch />

                            <div className={classes.separator} />

                            <LanguageSwitcher />

                            <div className={classes.separator} />

                            <QuickPanelToggleButton />
                        </div>
                    </Toolbar>
                </AppBar>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default React.memo(ToolbarLayout2);
