// import FuseAuthorization from '@fuse/core/FuseAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import history from '@history';
import { scrollToFragment } from 'scroll-to-fragment';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import rtl from 'jss-rtl';
import React from 'react';
import Provider from 'react-redux/es/components/Provider';
import { Router, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryParamProvider } from 'use-query-params';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n';
import AppContext from './AppContext';
// import { Auth } from './auth';
import routes from './fuse-configs/routesConfig';
import store, { persistor } from './store';

const jss = create({
	...jssPreset(),
	plugins: [...jssPreset().plugins, jssExtend(), rtl()],
	insertionPoint: document.getElementById('jss-insertion-point')
});

const generateClassName = createGenerateClassName();

scrollToFragment({ history });

const queryClient = new QueryClient();

const App = () => {
	return (
		<AppContext.Provider
			value={{
				routes
			}}
		>
			{/* <StyledEngineProvider injectFirst> These aren't even needed. See <FuseTheme />
                <ThemeProvider theme={theme}> */}
			<StylesProvider jss={jss} generateClassName={generateClassName}>
				<Provider store={store}>
					{/* I18nextProvider *shouldn't* be needed with React.Suspense is use but there seems to be some bug */}
					<I18nextProvider i18n={i18n}>
						<PersistGate persistor={persistor} loading={null}>
							{/* <Auth> */}
							<Router history={history}>
								<QueryParamProvider ReactRouterRoute={Route}>
								    <QueryClientProvider client={queryClient}>
										{/* <FuseAuthorization> */}
										<FuseTheme>
											<FuseLayout />
										</FuseTheme>
										{/* </FuseAuthorization> */}
									</QueryClientProvider>
								</QueryParamProvider>
							</Router>
							{/* </Auth> */}
						</PersistGate>
					</I18nextProvider>
				</Provider>
			</StylesProvider>
			{/* </ThemeProvider>
            </StyledEngineProvider> */}
		</AppContext.Provider>
	);
};

export default App;
