import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, Box, DialogContentText, Typography } from '@mui/material';
import { Warning as WarningIcon } from '@mui/icons-material';
import { VaultRecoveryKeyDownloadButton } from 'app/components/VaultManagementDialog';
import { useTranslation } from 'react-i18next';

interface Props {
	afterChange?: boolean;
	open: boolean;
	close: () => void;
	preventClose: boolean;
	setPreventClose: (value: boolean) => void;
}

const KeyDownloadDialog = ({ afterChange, open, close, preventClose, setPreventClose }: Props) => {
	const { t } = useTranslation();
	return (
		<Dialog open={open} onClose={() => !preventClose && close()} fullWidth maxWidth="xs">
			<DialogTitle id="vault-dialog-title">{t('vault options:recovery key:title')}</DialogTitle>
			<DialogContent>
				<Box display="flex" flexDirection="column" paddingY="1rem">
					<DialogContentText>{t('vault:recovery key message:1')}</DialogContentText>
					{!afterChange ? <DialogContentText>{t('vault:recovery key message:2')}</DialogContentText> : null}
					<DialogContentText>
						<Typography className="text-red uppercase">
							<WarningIcon />{' '}
							{!afterChange
								? t('vault:vault created recovery key message:2')
								: t('vault:recovery key message:3')}
						</Typography>
					</DialogContentText>
					<DialogContentText className="mb-24">{t('vault:recovery key message:4')}</DialogContentText>
					<VaultRecoveryKeyDownloadButton
						className="mt-10 self-center"
						onDownload={() => {
							if (preventClose) {
								setPreventClose(false);
							}
							close();
						}}
					/>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default KeyDownloadDialog;
