import React, { useState, useEffect } from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	Tabs,
	Tab,
	DialogContentText,
	Typography,
	ButtonProps
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import PasswordTextField from 'app/components/PasswordTextField';
import LoadingButton from 'app/components/LoadingButton';
import { useTranslation } from 'react-i18next';
import { getProfile, getUserVault } from 'app/store/reducers';
import { useSelector } from 'app/modules/react-redux';
import { UserVault } from '@sec/shield-guard-password-management';
import { Profile } from 'app/store/types';
import { uploadVault } from 'app/store/actions/vault.actions';

export function triggerRecoveryKeyDownload(filename: string, vault: UserVault, profile: Profile): void {
	const blob = new Blob([vault.recoveryKey], {
		type: 'application/octet-stream'
	});
	const link = document.createElement('a');
	link.download = `${filename} - ${profile.email}.sgvkey`;
	link.href = URL.createObjectURL(blob);
	link.onclick = () => setTimeout(() => URL.revokeObjectURL(link.href), 1000);
	link.click();
	link.remove();
}

interface ChangePasswordProps {
	vault: UserVault;
	confirmCurrent?: boolean;
	onUpdate?: () => void;
}

export function VaultPasswordChange({ vault, confirmCurrent = true, onUpdate }: ChangePasswordProps) {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmation, setConfirmation] = useState('');
	const [error, setError] = useState('');
	const [success, setSuccess] = useState(false);
	const [authError, setAuthError] = useState('');
	const [matchError, setMatchError] = useState('');

	async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		setLoading(true);
		setError('');
		setMatchError('');
		setAuthError('');
		setSuccess(false);

		if (newPassword !== confirmation) {
			setMatchError(t('Master keys do not match'));
			setLoading(false);
			return;
		}

		if (confirmCurrent && !(await vault.checkMasterKey(password))) {
			setAuthError(t('Current master key is incorrect'));
			setLoading(false);
			return;
		}

		setAuthError('');
		try {
			await vault.setNewMasterKey(newPassword);
			if (vault.needsUpload) await uploadVault(vault);
			setSuccess(true);
		} catch (err) {
			console.error(err);
			setError(t('change master key failed'));
		}
		setLoading(false);
		if (onUpdate) onUpdate();
	}

	function handleConfirmationChange(event: React.ChangeEvent<HTMLInputElement>) {
		setConfirmation(event.target.value);
		if (newPassword === confirmation) {
			setMatchError('');
		}
	}

	return (
		<form onSubmit={onSubmit}>
			<Box display="flex" flexDirection="column">
				{confirmCurrent && (
					<PasswordTextField
						className="mt-10 mb-10"
						label={t('vault:current password label')}
						value={password}
						variant="outlined"
						onChange={event => setPassword(event.target.value)}
						error={authError !== ''}
						helperText={authError}
					/>
				)}
				<PasswordTextField
					className="mb-10"
					label={t('vault:new password label')}
					value={newPassword}
					variant="outlined"
					onChange={event => setNewPassword(event.target.value)}
				/>
				<PasswordTextField
					className="mb-10"
					label={t('vault:confirm password label')}
					value={confirmation}
					variant="outlined"
					onChange={handleConfirmationChange}
					error={matchError !== ''}
					helperText={matchError}
				/>
				{error !== '' && <span className="text-red-500 mb-8 self-center">{error}</span>}
				{success && (
					<span className="text-green-500 mb-8 self-center">{t('vault:password change success')}</span>
				)}
			</Box>
			<div className="flex justify-end mt-8">
				<LoadingButton
					type="submit"
					loading={loading}
					disabled={(confirmCurrent && password === '') || newPassword === '' || confirmation === ''}
				>
					{t('Change Master Key')}
				</LoadingButton>
			</div>
		</form>
	);
}

type VaultRecoveryKeyDownloadProps = ButtonProps & {
	onDownload?: () => void;
	children?: React.ReactNode;
};

export function VaultRecoveryKeyDownloadButton({ onDownload, children, ...props }: VaultRecoveryKeyDownloadProps) {
	const { t } = useTranslation();

	const vault = useSelector(getUserVault) as UserVault;
	const profile = useSelector(getProfile);

	function onClick() {
		triggerRecoveryKeyDownload(t('recovery key filename'), vault, profile);
		if (onDownload) onDownload();
	}

	return (
		<Button onClick={onClick} variant="contained" color="primary" {...props}>
			{children ?? t('Download')}
		</Button>
	);
}

interface TabPanelProps {
	children?: React.ReactNode;
	tabId: TabId;
	activeTab: TabId;
}

type TabId = 'changePassword' | 'recoveryKey';

function TabPanel(props: TabPanelProps) {
	const { children, activeTab: value, tabId: index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vault-mgmt-tabpanel-${index}`}
			aria-labelledby={`vault-mgmt-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	);
}

type Props = {
	open: boolean;
	toggleOpen: () => void;
	view: TabId;
};

export default function VaultManagementDialog({ open, toggleOpen, view }: Props) {
	const { t } = useTranslation();
	const vault = useSelector(getUserVault) as UserVault;
	const profile = useSelector(getProfile);
	const [activeTab, setActiveTab] = useState(view);
	const [preventClose, setPreventClose] = useState(false);
	const [message, setMessage] = useState<string | undefined>(undefined);

	// const handleTabChange = (_event: unknown, newValue: number) => {
	// 	const tabValues: TabId[] = ['changePassword', 'recoveryKey'];
	// 	setActiveTab(tabValues[newValue]);
	// };

	useEffect(() => {
		if (open) {
			setActiveTab(view);
		}
	}, [open, view]);

	function close(force: boolean = false) {
		if (!preventClose || force) {
			toggleOpen();
			setMessage(undefined);
		}
	}

	return (
		<Dialog open={open} onClose={() => close()} fullWidth maxWidth="xs">
			<DialogTitle id="vault-dialog-title">
				{activeTab === 'recoveryKey'
					? t('vault options:recovery key:title')
					: t('vault options:change master key:title')}
			</DialogTitle>
			<DialogContent>
				{/* <Box borderBottom="1px solid gray">
					<Tabs
						value={(['changePassword', 'recoveryKey'] as TabId[]).indexOf(activeTab)}
						onChange={handleTabChange}
					>
						<Tab label={t('Change Master Key')} />
						<Tab label={t('Recovery Key')} />
					</Tabs>
				</Box> */}
				<TabPanel activeTab={activeTab} tabId="changePassword">
					<VaultPasswordChange
						vault={vault}
						confirmCurrent={false}
						onUpdate={() => {
							setPreventClose(true);
							setMessage(t('vault:password change success'));
							setActiveTab('recoveryKey');
						}}
					/>
				</TabPanel>
				<TabPanel activeTab={activeTab} tabId="recoveryKey">
					<Box display="flex" flexDirection="column" paddingY="1rem">
						<DialogContentText>{t('vault:recovery key message:1')}</DialogContentText>
						<DialogContentText>{t('vault:recovery key message:2')}</DialogContentText>
						<DialogContentText>
							<Typography className="text-red uppercase">
								<WarningIcon /> {t('vault:recovery key message:3')}
							</Typography>
						</DialogContentText>
						<DialogContentText className="mb-24">{t('vault:recovery key message:4')}</DialogContentText>
						<VaultRecoveryKeyDownloadButton
							className="mt-10 self-center"
							onDownload={() => {
								if (preventClose) {
									setPreventClose(false);
								}
								close(true);
							}}
						/>
					</Box>
				</TabPanel>
			</DialogContent>
		</Dialog>
	);
}
