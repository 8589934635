import React, { useState, useEffect } from 'react';
import Link from '@mui/material/Link';
import HelpIcon from '@mui/icons-material/Help';

import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TableHead
} from '@mui/material';

import WarningIcon from '@mui/icons-material/Warning';
import PasswordTextField from 'app/components/PasswordTextField';
import LoadingButton from 'app/components/LoadingButton';
import { useTranslation } from 'react-i18next';
import * as Actions from 'app/store/actions';
import {
	getIsVaultCreated,
	getLockedUserVault,
	getIsVaultUnlocked,
	getLicenseGroups,
	getProfile,
	getUserVault
} from 'app/store/reducers';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import { useDispatch, useSelector } from 'app/modules/react-redux';
import { LicenseGroupData, VaultLicenseGroupData } from 'app/store/types';
import { isNil } from 'lodash';
import { VaultPasswordChange, VaultRecoveryKeyDownloadButton } from 'app/components/VaultManagementDialog';
import { UserVault } from '@sec/shield-guard-password-management';
import CreateVaultDialog from './components/CreateVaultDialog';
import AppManagedUnlockDialog from './components/AppManagedUnlockDialog';
import KeyDownloadDialog from './components/KeyDownloadDialog';
import axios from "app/client";


const useStyles = makeStyles((theme: Theme) => ({
	tableHeader:
	{
		'& .MuiTableCell-root': {
			backgroundColor: theme.palette.primary.main,
			borderBottom: 'none',
			padding: '6px 24px 6px 16px',
			'&:first-child': {
				borderTopLeftRadius: 9999,
				borderBottomLeftRadius: 9999
			},
			'&:last-child': {
				borderTopRightRadius: 9999,
				borderBottomRightRadius: 9999
			}
		},
		'& *': {
			color: '#FFFFFF !important'
		}
	},
	text: {
		margin: '9px 0px'
	},
	table: {
		marginBottom: '16px'
	}
}));

type Props = {
	open: boolean;
	setOpen: (open: boolean) => void;
	warnBeforeLeave?: boolean;
	onClose?: () => void;
};

const VaultDialog = ({ open, setOpen, warnBeforeLeave = false, onClose = undefined }: Props) => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const classes = useStyles();
	const isVaultCreated = useSelector(getIsVaultCreated);
	const lockedUserVault = useSelector(getLockedUserVault);
	const userVault = useSelector(getUserVault);
	const isVaultUnlocked = useSelector(getIsVaultUnlocked);
	const profile = useSelector(getProfile);
	const profileId = profile?.id;
	const userVaultType = profile.vaultType;
	const licenseGroups = (useSelector(getLicenseGroups) ?? []).reduce((licenseGroups, { id, ownerId, name, currentUsers }) => {
		licenseGroups[id] = {
			id,
			name,
			currentUsers,
			isOwner: !isNil(profileId) && ownerId === profileId
		};
		return licenseGroups;
	}, {} as Record<LicenseGroupData['id'], VaultLicenseGroupData>); // FIXME:::this dialog shouldn't be called in a context where getLicenseGroups is `undefined`
	const licenseGroupsList = Object.values(licenseGroups);

	const licenseGroupsFilteredByRecoveryStatus = (useSelector(getLicenseGroups) ?? []).reduce((licenseGroups, { id, ownerId, currentUserLostVaultKey, currentUsers, name, expirationDate }) => {
		return currentUserLostVaultKey ?
			{
				...licenseGroups
			} :
			{
				...licenseGroups,
				[id]: {
					id,
					name,
					isOwner: !isNil(profileId) && ownerId === profileId,
					currentUsers,
					currentUserLostVaultKey,
					expired: expirationDate ? Date.now() >= expirationDate : false 
				}
			}
	}, {} as Record<LicenseGroupData['id'], VaultLicenseGroupData>); // FIXME:::this dialog shouldn't be called in a context where getLicenseGroups is `undefined`

	const userTenants = [];
	for (const values of Object.values(licenseGroups)) {
		userTenants.push(values);
	}
	const singleUserTenants = userTenants.some(item => item.currentUsers === 1);
	const multiUserTenants = userTenants.some(item => item?.currentUsers !== undefined && item?.currentUsers > 1);

	const [password, setPassword] = useState<string | ArrayBuffer>('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');

	const [pendingForm, setPendingForm] = useState(false);
	const [showRecovery, setShowRecovery] = useState(false);
	const [showUserInitiatedReset, setShowUserInitiatedReset] = useState(false);
	const [showChangePassword, setShowChangePassword] = useState(false);
	const [showKeyDownload, setShowKeyDownload] = useState(false);
	const [showKeyDownloadAfterChange, setShowKeyDownloadAfterChange] = useState(false);
	const [preventClose, setPreventClose] = useState(false);
	const [tenantUserAssistCheckbox, setTenantUserAssistCheckbox] = useState(false);
	const [technicianAssistCheckbox, setTechnicianAssistCheckbox] = useState(false);
	const [loading, setLoading] = useState(false);
	const disabledResetVaultSubmit = (singleUserTenants && multiUserTenants && technicianAssistCheckbox && tenantUserAssistCheckbox) || (!singleUserTenants && multiUserTenants && tenantUserAssistCheckbox) || (!multiUserTenants && singleUserTenants && technicianAssistCheckbox);

	useEffect(() => {
		if (!isVaultCreated) {
			setLoading(false);
			setShowUserInitiatedReset(false);
		}
	}, [isVaultCreated])

	useEffect(() => {
		const onBeforeUnload = (e: BeforeUnloadEvent) => {
			e.preventDefault();
			e.returnValue = '';
		};

		if (warnBeforeLeave && !isVaultUnlocked) {
			window.addEventListener('beforeunload', onBeforeUnload);
		} else {
			window.removeEventListener('beforeunload', onBeforeUnload);
		}

		return () => {
			window.removeEventListener('beforeunload', onBeforeUnload);
		};
	}, [isVaultUnlocked, warnBeforeLeave]);

	let title: string;
	let message: React.ReactNode[];
	let successMessage;
	let disabled;
	let onClick: (
		passwordValue?: typeof password,
		encryptedKey?: string,
	) => (onSuccess?: () => void, onError?: () => void) => ReturnType<typeof Actions.createVaults>;
	let buttonText;

	if (/*!user?.vaultReset && */ !isVaultCreated) {
		title = t('vault:create title');
		message = [
			t('vault:create message:1'),
			t('vault:create message:2'),
			<Typography className="text-red uppercase mb-24">
				<WarningIcon /> {t('vault:create message:3')}
			</Typography>
		];
		successMessage = t('vault:create vault success');
		disabled =
			(password === '' || passwordConfirmation === '' || passwordConfirmation !== password)
		onClick = (passwordValue, encryptedKey = '') => onSuccess => {
			return Actions.createVaults((passwordValue ?? password) as string, encryptedKey, licenseGroupsFilteredByRecoveryStatus, onSuccess);
		}

		buttonText = t('Create');
	} else {
		title = t('vault:unlock title');
		message = [t('vault:unlock message:1')];
		successMessage = t('vault:password change success');
		disabled = password === '';
		buttonText = t('Unlock');
		onClick = passwordValue => (onSuccess, onError) => {
			return Actions.decryptVaults(passwordValue ?? password, licenseGroups, licenseGroupsFilteredByRecoveryStatus, lockedUserVault, onSuccess, onError);
		}
		buttonText = t('Unlock');
	}

	// clear password field when `showRecovery` view is left
	useEffect(() => {
		if (showRecovery === false) {
			setPassword('');
		}
	}, [showRecovery]);

	function close() {
		onClose !== undefined && onClose();
		setOpen(false);
		setPassword('');
		setPasswordConfirmation('');
		setShowRecovery(false);
		setShowChangePassword(false);
		setShowKeyDownload(false);
		setShowKeyDownloadAfterChange(false);
	}

	function closeReset() {
		setShowUserInitiatedReset(false);
		setShowRecovery(true);
		setTenantUserAssistCheckbox(false);
		setTechnicianAssistCheckbox(false);
	}

	function handleSubmit() {
		setLoading(true);
		dispatch(Actions.flagUserVaultReset(profileId));
		setTenantUserAssistCheckbox(false);
		setTechnicianAssistCheckbox(false);
		close();
	}

	if (showRecovery) {
		const onSubmitRecovery = async (passwordValue: typeof password) => {
			setPendingForm(true);
			await dispatch(
				onClick(passwordValue)(
					() => {
						setPreventClose(true);
						setShowRecovery(false);
						setShowChangePassword(true);
						setPendingForm(false);
					},
					() => {
						dispatch(Actions.alert('vault:bad recovery key error', 'error'));
						setPendingForm(false);
					}
				)
			);
		};
		return (
			<Dialog open={open} onClose={() => !preventClose && close()} fullWidth maxWidth="xs">
				<DialogTitle id="vault-dialog-title">{title}</DialogTitle>
				<form>
					<DialogContent>
						<Box display="flex" flexDirection="column">
							<DialogContentText className="mb-24">{t('Provide Recovery Key')}</DialogContentText>
							<Button
								className="self-center"
								variant="contained"
								component="label"
								color="primary"
								disabled={pendingForm}
							>
								{t('select recovery key')}
								<input
									type="file"
									accept=".sgvkey"
									hidden
									// clear file on click (so same file upload re-triggers any actions)
									onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
										(event.target as HTMLInputElement).value = '';
									}}
									onChange={async event => {
										if (event.target.files && event.target.files.length > 0) {
											const file = event.target.files[0];
											setPendingForm(true);
											const value = await file.arrayBuffer();
											setPassword(value);
											// HACK-ish::pass new value of password instead of getting it from state so I don't have to refactor all of this to use `useEffect` (because it was getting old state)
											onSubmitRecovery(value);
										}
									}}
								/>
							</Button>
							<Button
								className="mt-8 normal-case self-center"
								variant="text"
								color="secondary"
								onClick={() => { setShowUserInitiatedReset(true); setShowRecovery(false); }}
							>
								{t('vault:cant find key')}
							</Button>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setShowRecovery(false)}>{t('Back')}</Button>
						{/* HACK-ish::this button is always disabled and is just used to show pending */}
						<LoadingButton type="submit" loading={pendingForm} disabled>
							{buttonText}
						</LoadingButton>
					</DialogActions>
				</form>
			</Dialog>
		);
	}

	if (showChangePassword) {
		return (
			<Dialog open={open} onClose={() => !preventClose && close()} fullWidth maxWidth="xs">
				<DialogTitle id="vault-dialog-title">{t('vault options:change master key:title')}</DialogTitle>
				<DialogContent>
					<VaultPasswordChange
						vault={userVault as UserVault}
						confirmCurrent={false}
						onUpdate={() => {
							setShowChangePassword(false);
							setShowKeyDownloadAfterChange(true);
						}}
					/>
				</DialogContent>
			</Dialog>
		);
	}

	if (showKeyDownload) {
		return (<KeyDownloadDialog open={open} close={close} preventClose={preventClose} setPreventClose={setPreventClose} />)
	}

	if (showKeyDownloadAfterChange)
		return (<KeyDownloadDialog afterChange open={open} close={close} preventClose={preventClose} setPreventClose={setPreventClose} />)

	if (showUserInitiatedReset) {
		return (
			<Dialog open={showUserInitiatedReset} onClose={() => closeReset()} scroll='paper' id="scroll-dialog-title">
				<DialogTitle id="vault-dialog-title">{t('vault:reset vault')}</DialogTitle>
				<DialogContent>
					<Typography>
						{t('vault:reset vault info text')}
					</Typography>
					{userTenants.length > 0 &&
						<>
							<Typography className="my-16">
								{t('vault:reset vault info text1')}
							</Typography>
							<Table className={classes.table}>
								<TableHead className={classes.tableHeader}>
									<TableCell>{t('settings:license group:title')}</TableCell>
									<TableCell>{t('vault:recovery options')}</TableCell>
								</TableHead>
								<TableBody>
									{licenseGroupsList.map(item => (
										<TableRow>
											<TableCell>
												{item?.name}
											</TableCell>
											<TableCell>
												{item?.currentUsers !== undefined && item?.currentUsers > 1 ? t('vault:user assisted recovery') : t('vault:manual recovery')}
											</TableCell>
										</TableRow>)
									)}
								</TableBody>

							</Table>
						</>}
					{multiUserTenants && (
						<>
							<Typography className="mb-9 flex flex-row">
								<Checkbox
									className="pb-9 pt-0 pl-0 pr-9 flex self-start"
									onClick={() => setTenantUserAssistCheckbox(prev => !prev)}
								/>
								<span>
									{t('vault:reset checkbox')}
									<div className="inline italic text-red-500 pl-4 whitespace-no-wrap">
										{t('required asterisk')}
									</div>
								</span>
							</Typography>
						</>)
					}
					{singleUserTenants &&
						<>
							<Typography className={classes.text}>
								{t('vault:reset warning')}
							</Typography>
							<Typography className="flex flex-row">
								<Checkbox
									className="pb-9 pt-0 pl-0 pr-9 flex self-start"
									onClick={() => setTechnicianAssistCheckbox(prev => !prev)}
								/>
								<span>
									{t('vault:reset checkbox2')}
									<div className="inline italic text-red-500 pl-4 whitespace-no-wrap">
										{t('required asterisk')}
									</div>
								</span>
							</Typography>
						</>
					}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => closeReset()}>
						{t('Cancel')}
					</Button>
					<LoadingButton onClick={handleSubmit} loading={loading} disabled={userTenants.length > 0 ? !disabledResetVaultSubmit : false} variant='contained' color='secondary'>
						{t('Save')}
					</LoadingButton>
				</DialogActions>
			</Dialog>
		)
	}

	if (!isVaultCreated) {
		return (
			<CreateVaultDialog
				onCreate={async (event, password, selectedVaultType) => {
					event.preventDefault();
					setPendingForm(true);
					if (selectedVaultType === 'app') {
						const response = await axios.get('/api/v1/vault/appManage');
						const { PlaintextKey, EncryptedKey } = response.data;
						await dispatch(
							onClick(PlaintextKey, EncryptedKey)(() => {
								setPreventClose(false);
								close();
							})
						);
					} else {
						await dispatch(
							onClick(password)(() => {
								setPreventClose(false);
								setShowKeyDownload(true);
							})
						);
					}
					setPendingForm(false);
				}}
				open={open}
				close={close}
				preventClose={preventClose}
				pendingForm={pendingForm}
				password={password}
				setPassword={setPassword}
			/>
		)
	}

	if (userVaultType === 'app') {
		return <AppManagedUnlockDialog
			filteredLicenseGroups={licenseGroupsFilteredByRecoveryStatus}
			licenseGroups={licenseGroups}
			close={close}
		/>
	} else if (userVaultType === 'self') {
		return (
			<Dialog open={open} onClose={() => !preventClose && close()} fullWidth maxWidth="xs">
				<DialogTitle id="vault-dialog-title" className="flex">
					{t('vault:unlock title')}
					<Link
						// FIXME::need to add `path` support
						// href={`${process.env.REACT_APP_HELP_URL}?locale=${i18n.language}&path=${encodeURIComponent(
						// 	'shield-guard-portal/admin-area/#password-vault'
						// )}`}
						href={`${process.env.REACT_APP_HELP_URL}/${i18n.language.slice(
							0,
							2
						)}/shield-guard-portal/password-vaults/#unlocking-your-password-vault`}
						target="_blank"
						rel="noreferrer noopener"
						style={{marginLeft: '252px'}}
					>
						<HelpIcon color="secondary" />
					</Link>
				</DialogTitle>
				<form
					onSubmit={async event => {
						event.preventDefault();
						setPendingForm(true);
						await dispatch(
							onClick()(() => {
								close();
							})
						);
						setPendingForm(false);
					}}
				>
					<DialogContent>
						{message.map(line => (
							<DialogContentText>{line}</DialogContentText>
						))}
						<DialogContentText>
						</DialogContentText>

						<PasswordTextField
							autoFocus
							className="mt-24 mb-8"
							variant="outlined"
							label={t('vault:password label')}
							fullWidth
							autoComplete={'current-password'}
							value={password}
							onChange={event => setPassword(event.target.value)}
						/>
						<Button
							className="normal-case"
							variant="text"
							color="secondary"
							onClick={() => setShowRecovery(true)}
						>
							{t('vault:forgot master key link')}
						</Button>
					</DialogContent>
					<DialogActions>
						<LoadingButton type="submit" loading={pendingForm} disabled={disabled}>
							{buttonText}
						</LoadingButton>
					</DialogActions>
				</form>
			</Dialog>
		);
	}

	return (<div></div>)


};

export default VaultDialog;
