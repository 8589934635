import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
// import * as authActions from 'app/auth/store/actions';
import React, { useState } from 'react';
import { LicensingIcon, LogoutIcon } from 'app/components/Icons';
import {
	getIsSelectedLicenseGroupSubscriptionLicense,
	getProfile,
	getLicenseGroups,
	getProfilePermissionCheck,
	getSelectedLicenseGroupData,
} from 'app/store/reducers';
import { useSelector } from 'app/modules/react-redux';

import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Help from '@mui/icons-material/Help';

const UserMenu = ({ adminPage = false }: { adminPage: boolean }) => {
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const profile = useSelector(getProfile);
	const licenseGroups = useSelector(getLicenseGroups) as ReturnType<typeof getLicenseGroups> | undefined; // HACK::use actual type here - assume never undefined within the wrapped app
	const licenseGroupData = useSelector(getSelectedLicenseGroupData) as
		| ReturnType<typeof getSelectedLicenseGroupData>
		| undefined; // HACK::use actual type here - assume never undefined within the wrapped app

	const isSubscriptionLicense = useSelector(getIsSelectedLicenseGroupSubscriptionLicense);
	const profilePermissionCheck = useSelector(getProfilePermissionCheck);

	const LicensingPageLink =
		licenseGroupData && profilePermissionCheck('license_management')
			? isSubscriptionLicense
				? '/admin/modify-subscription'
				: '/admin/term-licenses'
			: undefined;
	const [userMenu, setUserMenu] = useState<HTMLButtonElement | null>(null);

	const userMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	// HACK::determine if looking at the profile page based on URL
	const profilePage = /^\/profile(\/.*)?/.test(location.pathname);

	return (
		<>
			<Button className="h-64 px-20 text-white" onClick={userMenuClick}>
				<AccountCircleIcon className="text-green-accent" />

				<div className="flex-col mx-12 items-start">
					<Typography component="span" className="normal-case font-600 flex">
						{t('header:welcome name', { name: profile.firstName })}
					</Typography>
				</div>

				<Icon className="text-green-accent">keyboard_arrow_down</Icon>
			</Button>

			<Popover
				open={!!userMenu}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8'
				}}
			>
				{(adminPage || profilePage) && (
					<MenuItem component={Link} to="/" onClick={userMenuClose} role="button">
						<ListItemIcon className="min-w-40">
							<Icon>storefront</Icon>
						</ListItemIcon>
						<ListItemText primary={t('Home')} />
					</MenuItem>
				)}

				{!adminPage && licenseGroups && licenseGroups.length > 0 && (
					<MenuItem component={Link} to="/admin" onClick={userMenuClose} role="button">
						<ListItemIcon className="min-w-40">
							<Icon>business</Icon>
						</ListItemIcon>
						<ListItemText primary={t('Admin')} />
					</MenuItem>
				)}

				{!profilePage && (
					<MenuItem component={Link} to="/profile" onClick={userMenuClose} role="button">
						<ListItemIcon className="min-w-40">
							<AccountCircleIcon />
						</ListItemIcon>
						<ListItemText primary={t('My Profile')} />
					</MenuItem>
				)}

				<a href={`${process.env.REACT_APP_HELP_URL}?locale=${i18n.language}`} target="-blank" role="button">
					<MenuItem>
						<ListItemIcon className="min-w-40">
							<Help />
						</ListItemIcon>
						<ListItemText primary={t('Help')} />
					</MenuItem>
				</a>

				{LicensingPageLink ? (
					<>
						<MenuItem component={Link} to={LicensingPageLink} onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<LicensingIcon />
							</ListItemIcon>
							<ListItemText primary={t('Licensing')} />
						</MenuItem>
					</>
				) : null}

				<Divider />
				<a href="/api/sso/logout" role="button">
					<MenuItem>
						<ListItemIcon className="min-w-40">
							<LogoutIcon />
						</ListItemIcon>
						<ListItemText primary={t('Logout')} />
					</MenuItem>
				</a>
			</Popover>
		</>
	);
};

export default UserMenu;
