import React from 'react';

const PricingPageConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/pricing',
			exact: true,
			component: React.lazy(() => import('./PricingPage'))
		}
	]
};

export default PricingPageConfig;
