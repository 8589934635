import * as React from 'react';
import { Link } from '@mui/material';
import { Help as HelpIcon } from '@mui/icons-material';
import i18n from 'i18n';

interface Props {
	section?: 'admin' | 'vaultManagement' | 'createVault';
}
const HelpPageButton = ({ section }: Props) => {
	let path;
	switch (section) {
		case 'admin':
			path = 'shield-guard-portal/admin-area/#password-vault';
			break;
		case 'vaultManagement':
			path = 'shield-guard-portal/password-vaults/#vault-key-management';
			break;
		case 'createVault':
			path = 'shield-guard-portal/password-vaults/#creating-your-password-vault';
			break;
		default:
			path = '';
			break;
	}
	return (
		<Link
			href={`${process.env.REACT_APP_HELP_URL}/${i18n.language.slice(0, 2)}/${path}`}
			target="_blank"
			rel="noreferrer noopener"
		>
			<HelpIcon color='secondary'/>
		</Link>
	);
};

export default HelpPageButton;
