import React from 'react';
import Link from '@mui/material/Link';
import MessageView from 'app/components/MessageView';
import usePageTitle from 'app/hooks/usePageTitle';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'app/modules/react-redux';
import { getIsPossibleDevelopStore } from 'app/store/reducers';

const NotAvailablePage = () => {
	const { t } = useTranslation();
	usePageTitle('Not Available');

	const isPossibleDevelopStore = useSelector(getIsPossibleDevelopStore); // TEMP::skip any buttons for now develop store (as we don't know which to direct to)

	return (
		<MessageView
			title={t('not available:title')}
			message={[t('not available:message'), t('not available:message subtext')]}
			footer={
				isPossibleDevelopStore ? (
					undefined
				) : (
					<Link
						color="inherit"
						className="font-medium"
						href="https://konicaminoltamarketplace.com/support/contact"
						target="_blank"
						rel="noreferrer noopener"
					>
						{t('Contact us')}
					</Link>
				)
			}
		/>
	);
};

export default NotAvailablePage;
