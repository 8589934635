import i18next, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Locize from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import common from './locales/en/common.json';
import alert from './locales/en/alert.json';
import pageTitle from './locales/en/pageTitle.json';
import securitySetting from './locales/en/securitySetting.json';
import log from './locales/en/log.json';

const locize = new Locize(
	{
		projectId: '3218ec73-3535-4909-932d-c36dce9497e8',
		// add an api key if you want to send missing keys
		apiKey: process.env.NODE_ENV === 'development' ? '7e43cc7f-e9f8-4458-a974-bb0b7fc1a19e' : undefined,
		// version - defaults to `latest`
		version: process.env.REACT_APP_LOCIZE_VERSION,
		whitelistThreshold: 0
	},
	(_: any, opts: InitOptions) => {
		// opts includes fallbackLng, load, referenceLng, and whitelist based on which languages we have on locize
		i18next
			.use(locize)
			.use(LanguageDetector)
			.use(initReactI18next)
			.init({
				...opts,
				debug: false,
				ns: ['common', 'alert', 'pageTitle', 'securitySetting', 'log'],
				defaultNS: 'common',
				returnEmptyString: false,
				keySeparator: ';',
				nsSeparator: '::',
				interpolation: {
					escapeValue: false
				},
				...(process.env.NODE_ENV === 'development' && {
					referenceLng: 'en',
					resources: {
						en: {
							common,
							alert,
							pageTitle,
							securitySetting,
							log
						}
					}
				})
			});
	}
);

// @ts-ignore // is this supposed to be `getResource`? I think it's wrong but I don't wanna change it now...
i18next.getResources = (lang, namespace) =>
	!i18next.hasResourceBundle(lang, namespace) && i18next.reloadResources(lang, namespace);

export default i18next;
