import FuseDialog from '@fuse/core/FuseDialog';
import FuseMessage from '@fuse/core/FuseMessage';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseSuspense from '@fuse/core/FuseSuspense';
import makeStyles from '@mui/styles/makeStyles';
import AppContext from 'app/AppContext';
// import SettingsPanel from 'app/fuse-layouts/shared-components/SettingsPanel';
import clsx from 'clsx';
import React, { useState } from 'react';
import { renderRoutes } from 'react-router-config';
import { useSelector } from 'app/modules/react-redux';
import { getHideNavbar } from 'app/store/reducers';
import ErrorBoundary from 'app/components/ErrorBoundary';
import InitializeApp from 'app/components/InitializeApp';
import CookieSettingsDialog from 'app/components/CookieSettingsDialog'
import FooterLayoutAdmin from './components/FooterLayoutAdmin';
import LeftSideLayoutAdmin from './components/LeftSideLayoutAdmin';
import NavbarWrapperLayoutAdmin from './components/NavbarWrapperLayoutAdmin';
import RightSideLayoutAdmin from './components/RightSideLayoutAdmin';
import ToolbarLayoutAdmin from './components/ToolbarLayoutAdmin';

const useStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		'&.boxed': {
			maxWidth: 1120,
			margin: '0 auto',
			boxShadow: theme.shadows[3]
		},
		'&.container': {
			'& .container': {
				maxWidth: 1120,
				width: '100%',
				margin: '0 auto'
			},
			'& .navigation': {}
		}
	},
	content: {
		display: 'flex',
		overflow: 'auto',
		flex: '1 1 auto',
		flexDirection: 'column',
		width: '100%',
		'-webkit-overflow-scrolling': 'touch',
		zIndex: 4
	},
	toolbarWrapper: {
		display: 'flex',
		position: 'relative',
		zIndex: 5
	},
	toolbar: {
		display: 'flex',
		flex: '1 0 auto'
	},
	footerWrapper: {
		position: 'relative',
		zIndex: 5
	},
	footer: {
		display: 'flex',
		flex: '1 0 auto'
	}
}));

function LayoutAdmin(props) {
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const hideNavbar = useSelector(getHideNavbar);

	const classes = useStyles(props);

	return (
		<AppContext.Consumer>
			{({ routes }) => (
				<FuseSuspense fullscreen>
					<ErrorBoundary>
						<InitializeApp>
							<div id="fuse-layout" className={clsx(classes.root, config.mode)}>
								{config.leftSidePanel.display && <LeftSideLayoutAdmin />}

								<div className="flex flex-1 flex-col overflow-hidden relative">
									{config.toolbar.display && config.toolbar.position === 'above' && (
										<ToolbarLayoutAdmin adminPage={config.adminPage} />
									)}

									{config.navbar.display && !hideNavbar && (
										<NavbarWrapperLayoutAdmin adminPage={config.adminPage} />
									)}

									{config.toolbar.display && config.toolbar.position === 'below' && (
										<ToolbarLayoutAdmin adminPage={config.adminPage} />
									)}

									<FuseScrollbars className={clsx(classes.content)} scrollToTopOnRouteChange>
										<FuseDialog />

										<div className="flex flex-auto flex-col relative h-full">
											<FuseSuspense>
												<ErrorBoundary>{renderRoutes(routes)}</ErrorBoundary>
											</FuseSuspense>

											{props.children}

											{config.footer.display && config.footer.style === 'static' && (
												<FooterLayoutAdmin />
											)}
										</div>
									</FuseScrollbars>

									{config.footer.display && config.footer.style === 'fixed' && <FooterLayoutAdmin />}

									{/* <SettingsPanel /> */}
								</div>

								{config.rightSidePanel.display && <RightSideLayoutAdmin />}

								<CookieSettingsDialog />

								<FuseMessage />
							</div>
						</InitializeApp>
					</ErrorBoundary>
				</FuseSuspense>
			)}
		</AppContext.Consumer>
	);
}

export default React.memo(LayoutAdmin);
