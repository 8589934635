// use this reducer for UI and user activity state (not data from the backend)
import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import type { RootState } from 'app/store';
import { AppState, AppActionTypes } from 'app/store/types';

const initialState: AppState = {
	selectedLicenseGroupId: undefined!, // HACK::assume never undefined and handle check only in <LicenseGroupPageWrapper />
	usersFilterText: '',
	devicesFilterText: '',
	policiesFilterText: '',
	logsFilterText: '',
	hideNavbar: false,
	cookieSettingsAcknowledged: false,
	showCookieSettings: false,
	hideChangelogBanner: false,
	hideExpiredBanner: false,
	hideExpiredScreen: false,
	modifiedLicenseGroupPricing: {
		currentPrice: undefined,
		modifiedPrice: undefined
	}
};

const appReducer = (state = initialState, action: AppActionTypes) => {
	switch (action.type) {
		case 'SET_SELECTED_LICENSE_GROUP': {
			return {
				...state,
				selectedLicenseGroupId: action.payload.selectedLicenseGroupId
			};
		}
		case 'SET_USERS_FILTER_TEXT': {
			return {
				...state,
				usersFilterText: action.payload.usersFilterText
			};
		}
		case 'SET_DEVICES_FILTER_TEXT': {
			return {
				...state,
				devicesFilterText: action.payload.devicesFilterText
			};
		}
		case 'SET_POLICIES_FILTER_TEXT': {
			return {
				...state,
				policiesFilterText: action.payload.policiesFilterText
			};
		}
		case 'SET_LOGS_FILTER_TEXT': {
			return {
				...state,
				logsFilterText: action.payload.logsFilterText
			};
		}
		case 'HIDE_NAVBAR': {
			return {
				...state,
				hideNavbar: action.payload.hide
			};
		}
		case 'ACKNOWLEDGE_COOKIE_NAGBAR': {
			return {
				...state,
				cookieSettingsAcknowledged: true
			};
		}
		case 'TOGGLE_COOKIE_SETTINGS_DIALOG': {
			return {
				...state,
				showCookieSettings: action.payload.showCookieSettings
			};
		}
		case 'HIDE_CHANGELOG_BANNER': {
			return {
				...state,
				hideChangelogBanner: true
			};
		}
		case 'HIDE_EXPIRED_BANNER': {
			return {
				...state,
				hideExpiredBanner: true
			};
		}
		case 'HIDE_EXPIRED_SCREEN': {
			return {
				...state,
				hideExpiredScreen: true
			};
		}
		// wipe selectedLicenseGroupId if id not found in returned license groups list
		case 'GET_LICENSE_GROUPS_SUCCESS': {
			const prevSelectedLicenseGroupId = state.selectedLicenseGroupId as string | undefined; // HACK::simplifying type by just being string but it can be both here

			return {
				...state,
				selectedLicenseGroupId:
					prevSelectedLicenseGroupId && action.payload.data && action.payload.data[prevSelectedLicenseGroupId]
						? prevSelectedLicenseGroupId
						: undefined!
			};
		}
		case 'GET_MODIFIED_LICENSE_GROUP_PRICING_SUCCESS': {
			const { currentPrice, modifiedPrice } = action.payload;

			return {
				...state,
				modifiedLicenseGroupPricing: {
					currentPrice,
					modifiedPrice
				}
			};
		}
		case 'LOGGED_OUT_USER':
			return {
				...initialState,
				hideChangelogBanner: state.hideChangelogBanner,
				cookieSettingsAcknowledged: state.cookieSettingsAcknowledged,
				hideNavbar: state.hideNavbar
			};
		case 'PURGE_STATE':
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'app',
		storage: localForage,
		whitelist: ['hideChangelogBanner', 'selectedLicenseGroupId', 'cookieSettingsAcknowledged'] // wipe most app state on refresh
	},
	appReducer
);

// Selectors
export const getSelectedLicenseGroupId = ({ app }: RootState) => app.selectedLicenseGroupId;
export const getUsersFilterText = ({ app }: RootState) => app.usersFilterText;
export const getDevicesFilterText = ({ app }: RootState) => app.devicesFilterText;
export const getPoliciesFilterText = ({ app }: RootState) => app.policiesFilterText;
export const getLogsFilterText = ({ app }: RootState) => app.logsFilterText;
export const getHideNavbar = ({ app }: RootState) => app.hideNavbar;
export const getCookieSettingsAcknowledged = ({ app }: RootState) => app.cookieSettingsAcknowledged;
export const getCookieSettings = ({ app }: RootState) => app.showCookieSettings;
export const getHideExpiredBanner = ({ app }: RootState) => app.hideExpiredBanner;
export const getHideChangelogBanner = ({ app }: RootState) => app.hideChangelogBanner;
export const getHideExpiredScreen = ({ app }: RootState) => app.hideExpiredScreen;
export const getModifiedLicenseGroupPricing = ({ app }: RootState) => app.modifiedLicenseGroupPricing;
