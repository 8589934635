import React from 'react';
import { DialogContentText } from '@mui/material';
import { WarningRounded as Warning } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const PasswordWarning = () => {
	const { t } = useTranslation();
	return (
		<DialogContentText className="text-red mb-32">
			<Warning className="mr-4" />
			{t('vault:password warning')}
		</DialogContentText>
	);
};

export default PasswordWarning;
