import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import type { RootState } from 'app/store';
import { Policy, PoliciesState, PoliciesActionTypes } from 'app/store/types';

const initialState: PoliciesState = {};

const policiesReducer = (state = initialState, action: PoliciesActionTypes) => {
	switch (action.type) {
		case 'GET_LICENSE_GROUP_DATA_SUCCESS': {
			const {
				licenseGroupId,
				data: { policies }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					byId: policies
				}
			};
		}
		case 'LOGGED_OUT_USER':
		case 'PURGE_STATE':
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'policies',
		storage: localForage
	},
	policiesReducer
);

// Selectors
export const getPolicies = ({ app: { selectedLicenseGroupId }, policies }: RootState) =>
	Object.values(policies[selectedLicenseGroupId].byId);

export const getPoliciesById = ({ app: { selectedLicenseGroupId }, policies }: RootState) =>
	policies[selectedLicenseGroupId].byId;

export const getPolicyById = (policyId: Policy['id']) => (state: RootState) => getPoliciesById(state)[policyId];

// no reason not to access this right off of the policy except I'd rather pretend they're not directly coupled
export const getPasswordConfigByPolicyId = (policyId: Policy['id']) => (state: RootState) =>
	getPolicyById(policyId)(state).passwordConfig;
