import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import MdiIcon from '@mdi/react';
import { mdiClockAlert } from '@mdi/js/mdi';
import { IconProps as MdiIconProps } from '@mdi/react/dist/IconProps';

export const Logo = (props: SvgIconProps) => (
	<SvgIcon viewBox="0 0 80 80" {...props}>
		<g id="Layer_1" style={{ display: 'none' }}>
			<circle
				style={{ display: 'inline', fill: 'none', stroke: '#231F20', strokeMiterlimit: 10 }}
				cx="58.9"
				cy="-10.6"
				r="176.4"
			/>
			<circle
				style={{ display: 'inline', fill: 'none', stroke: '#231F20', strokeMiterlimit: 10 }}
				cx={-136}
				cy="-10.6"
				r="176.4"
			/>
		</g>
		<g id="Layer_2" style={{ display: 'none' }}>
			<g style={{ display: 'inline' }}>
				<path
					style={{
						fillRule: 'evenodd',
						clipRule: 'evenodd',
						fill: '#8DC63F',
						stroke: '#8DC63F',
						strokeWidth: '9.1245',
						strokeLinejoin: 'round',
						strokeMiterlimit: 10
					}}
					d="
			M26.3-153.4c-24.9,24.9-98.3,43.3-145,43.3c0,232.3,83,259,121.7,297.7c28.7,28.7,23.3,0.7,23.3-11.3V-153.4L26.3-153.4z"
				/>
				<path
					style={{
						fillRule: 'evenodd',
						clipRule: 'evenodd',
						fill: '#FFFFFF',
						stroke: '#8DC63F',
						strokeWidth: '9.1245',
						strokeLinejoin: 'round',
						strokeMiterlimit: 10
					}}
					d="
			M25.4-153.4c24.6,24.9,98.1,43.3,144.8,43.3c0,231.9-82.8,258.9-121.4,297.5c-28.2,28.2-23.4,0.7-23.4-11.3V-153.4L25.4-153.4z"
				/>
			</g>
		</g>
		<g id="rounded" style={{ display: 'none' }}>
			<path
				style={{
					display: 'inline',
					fillRule: 'evenodd',
					clipRule: 'evenodd',
					fill: '#FFFFFF',
					stroke: '#8DC63F',
					strokeWidth: 10,
					strokeLinejoin: 'round',
					strokeMiterlimit: 10
				}}
				d="
		M26.1-155.8c0,0-21.3,15.6-62.6,33c-39.8,16.7-86.6,8.9-86.6,8.9s0,111.4,0,146.3c0,34.9,5.7,73.1,51.9,115.9
		c46.3,42.9,97.3,58.8,97.3,58.8s51-15.9,97.3-58.8c46.3-42.9,51.9-81,51.9-115.9c0-34.9,0-147.3,0-147.3s-40.4,10.7-80.2-6
		C53.7-138.2,26.1-155.8,26.1-155.8z"
			/>
			<g id="Layer_13" style={{ display: 'inline' }}>
				<polygon
					style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#8DC63F' }}
					points="26.1,-153.4 26.1,205.1 -41.1,175.3 -101.6,115.8
			-123.2,59.9 -123.2,-113.9 -86.3,-113.4 -31.9,-125.7 		"
				/>
			</g>
		</g>
		<g id="Layer_11" style={{ display: 'none' }}>
			<path
				id="OPT-security_x5F_24_1_"
				style={{ display: 'inline', fill: '#8DC63F' }}
				d="M26.3-148.3l-4.6,2.7c0,0-98.6,58-135.2,79.2l-4.5,2.6
		V72.8C-118,133,7.6,204.7,21.9,212.7l4.4,2.4l4.4-2.4c14.3-8,139.9-79.7,139.9-139.9V-63.7L26.3-148.3z M-99.9-53.3
		c34.3-19.9,107.3-62.9,126.2-74l119.4,70l-239,151.4c-4.1-7.4-6.7-14.6-6.7-21.3V-53.3z M-83,108.9L152.5-40.3v50.9L-49,142.4
		C-62,131.5-73.9,120.1-83,108.9z M26.3,194.5C10.9,185.6-12,171-34.1,154.2l186.6-122v40.6C152.5,113.3,65.3,172,26.3,194.5z"
			/>
		</g>
		<g id="Layer_2_copy" style={{ display: 'none' }}>
			<g style={{ display: 'inline' }}>
				<path
					style={{
						fillRule: 'evenodd',
						clipRule: 'evenodd',
						fill: '#FFFFFF',
						stroke: '#8DC63F',
						strokeWidth: '9.1245',
						strokeMiterlimit: 10
					}}
					d="
			M25.4-153.4c24.6,24.9,98.1,43.3,144.8,43.3c0,288.5-121.4,267.5-144.8,325.5C25.4,98.9,25.4-37.2,25.4-153.4L25.4-153.4z"
				/>
			</g>
		</g>
		<g id="Layer_3" style={{ display: 'none' }}>
			<path
				style={{
					display: 'inline',
					fill: '#FFFFFF',
					stroke: '#8DC63F',
					strokeWidth: '9.1245',
					strokeMiterlimit: 10
				}}
				d="M199.9,25.1
		c1.2-5.1,1.8-10.5,1.8-15.9c0-39.9-32.3-72.2-72.2-72.2C95.1-63,66.3-38.9,59.1-6.6c-7-3.6-14.8-5.6-23.1-5.6
		c-28.1,0-50.8,22.7-50.8,50.8c0,3.3,0.3,6.6,0.9,9.7c-1-0.1-1.9-0.1-2.9-0.1c-23.3,0-42.1,18.9-42.1,42.1
		c0,17.4,10.6,32.4,25.7,38.8c0,0,3.3,2.5,27.4,1.9h181.2c30.8,0,55.8-25,55.8-55.8C231.1,53.2,218.4,34.2,199.9,25.1z"
			/>
		</g>
		<g id="Layer_3_copy_2" style={{ display: 'none' }}>
			<path
				style={{
					display: 'inline',
					fill: '#FFFFFF',
					stroke: '#8DC63F',
					strokeWidth: '9.1245',
					strokeMiterlimit: 10
				}}
				d="M-48.7,35.3
		c-1.2-5.1-1.8-10.5-1.8-15.9c0-39.9,32.3-72.2,72.2-72.2c34.4,0,63.2,24.1,70.4,56.4c7-3.6,14.8-5.6,23.1-5.6
		c28.1,0,50.8,22.7,50.8,50.8c0,3.3-0.3,6.6-0.9,9.7c1-0.1,1.9-0.1,2.9-0.1c23.3,0,42.1,18.9,42.1,42.1c0,17.4-10.6,32.4-25.7,38.8
		c0,0-3.3,2.5-27.4,1.9H-24.1c-30.8,0-55.8-25-55.8-55.8C-79.9,63.3-67.2,44.3-48.7,35.3z"
			/>
		</g>
		<g id="Layer_2_copy_2" style={{ display: 'none' }}>
			<g style={{ display: 'inline' }}>
				<path
					style={{
						fillRule: 'evenodd',
						clipRule: 'evenodd',
						fill: '#0062C2',
						stroke: '#0062C2',
						strokeWidth: '9.1245',
						strokeLinejoin: 'round',
						strokeMiterlimit: 10
					}}
					d="
			M25.4-153.4c-24.9,24.9-98.3,43.3-145,43.3c0,288.5,121.4,267.5,145,325.5C25.4,98.9,25.4-37.2,25.4-153.4L25.4-153.4z"
				/>
				<path
					style={{
						fillRule: 'evenodd',
						clipRule: 'evenodd',
						fill: '#FFFFFF',
						stroke: '#0062C2',
						strokeWidth: '9.1245',
						strokeLinejoin: 'round',
						strokeMiterlimit: 10
					}}
					d="
			M25.4-153.4c24.6,24.9,98.1,43.3,144.8,43.3c0,288.5-121.4,267.5-144.8,325.5C25.4,98.9,25.4-37.2,25.4-153.4L25.4-153.4z"
				/>
			</g>
		</g>
		<g id="Layer_3_copy_3" style={{ display: 'none' }}>
			<path
				style={{
					display: 'inline',
					fill: '#FFFFFF',
					stroke: '#0062C2',
					strokeWidth: '9.1245',
					strokeMiterlimit: 10
				}}
				d="M-53.8,34.2
		c-1.2-5.1-1.8-10.5-1.8-15.9c0-39.9,32.3-72.2,72.2-72.2C51-53.9,79.8-29.8,87,2.5c7-3.6,14.8-5.6,23.1-5.6
		c28.1,0,50.8,22.7,50.8,50.8c0,3.3-0.3,6.6-0.9,9.7c1-0.1,1.9-0.1,2.9-0.1c23.3,0,42.1,18.9,42.1,42.1c0,17.4-10.6,32.4-25.7,38.8
		c0,0-3.3,2.5-27.4,1.9H-29.3c-30.8,0-55.8-25-55.8-55.8C-85,62.3-72.3,43.3-53.8,34.2z"
			/>
		</g>
		<g id="Layer_2_copy_4">
			<g>
				<path
					style={{
						fillRule: 'evenodd',
						clipRule: 'evenodd',
						fill: '#8DC63F',
						stroke: '#8DC63F',
						strokeWidth: '1.9028',
						strokeLinejoin: 'round',
						strokeMiterlimit: 10
					}}
					d="
			M40.7,1.6c-5.2,5.2-20.5,9-30.2,9c0,60.2,30.2,63.9,30.2,67.9C40.7,54.2,40.7,25.9,40.7,1.6L40.7,1.6z"
				/>
				<path
					style={{
						fillRule: 'evenodd',
						clipRule: 'evenodd',
						fill: '#FFFFFF',
						stroke: '#8DC63F',
						strokeWidth: '1.9028',
						strokeLinejoin: 'round',
						strokeMiterlimit: 10
					}}
					d="
			M40.7,1.6c5.1,5.2,20.5,9,30.2,9c0,60.2-30.2,63.9-30.2,67.9C40.7,54.2,40.7,25.9,40.7,1.6L40.7,1.6z"
				/>
				<path
					style={{
						display: 'none',
						fillRule: 'evenodd',
						clipRule: 'evenodd',
						fill: '#FFFFFF',
						stroke: '#8DC63F',
						strokeWidth: '1.9028',
						strokeLinejoin: 'round',
						strokeMiterlimit: 10
					}}
					d="
			M70.9,10.7c0,60.2-30.2,63.8-30.2,67.9c0-24.3,0-52.7,0-76.9"
				/>
				<path
					style={{
						display: 'none',
						fillRule: 'evenodd',
						clipRule: 'evenodd',
						fill: '#8DC63F',
						stroke: '#8DC63F',
						strokeWidth: '1.9028',
						strokeLinejoin: 'round',
						strokeMiterlimit: 10
					}}
					d="
			M10.5,10.7c0,60.2,30.2,64.7,30.2,67.9c0-24.3,0-52.7,0-76.9"
				/>
			</g>
			<g id="Layer_16">
				<polygon
					style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#8DC63F' }}
					points="40,79.1 31.1,72.3 39.1,73.2 		"
				/>
			</g>
			<g id="Layer_17">
				<polygon
					style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#8DC63F' }}
					points="41,79.4 41.4,79.2 49,73.3 43.5,75.9 39.7,78.1 		"
				/>
			</g>
			<g id="Layer_18">
				<polygon
					style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FFFFFF' }}
					points="41.8,3.8 40.7,2.9 40.7,77.3 47.1,72.4 		"
				/>
			</g>
		</g>
		<g id="Layer_3_copy_5">
			<path
				style={{ fill: '#FFFFFF', stroke: '#8DC63F', strokeWidth: '1.5236', strokeMiterlimit: 10 }}
				d="M27.1,39.7c-0.2-0.9-0.3-1.7-0.3-2.7
		c0-6.7,5.4-12.1,12.1-12.1c5.7,0,10.6,4,11.8,9.4c1.2-0.6,2.5-0.9,3.9-0.9c4.7,0,8.5,3.8,8.5,8.5c0,0.6-0.1,1.1-0.2,1.6
		c0.2,0,0.3,0,0.5,0c3.9,0,7,3.1,7,7c0,2.9-1.8,5.4-4.3,6.5c0,0-0.6,0.4-4.6,0.3H31.2c-5.1,0-9.3-4.2-9.3-9.3
		C21.8,44.4,24,41.2,27.1,39.7z"
			/>
		</g>
		<g id="Layer_2_copy_3" style={{ display: 'none' }}>
			<g style={{ display: 'inline' }}>
				<path
					style={{
						fillRule: 'evenodd',
						clipRule: 'evenodd',
						fill: '#009EB7',
						stroke: '#009EB7',
						strokeWidth: '9.1245',
						strokeMiterlimit: 10
					}}
					d="
			M17.6-153.4c-24.9,24.9-98.3,43.3-145,43.3c0,288.5,121.4,267.5,145,325.5C17.6,98.9,17.6-37.2,17.6-153.4L17.6-153.4z"
				/>
				<path
					style={{
						fillRule: 'evenodd',
						clipRule: 'evenodd',
						fill: '#FFFFFF',
						stroke: '#009EB7',
						strokeWidth: '9.1245',
						strokeMiterlimit: 10
					}}
					d="
			M25.4-153.4c24.6,24.9,98.1,43.3,144.8,43.3c0,288.5-121.4,267.5-144.8,325.5C25.4,98.9,25.4-37.2,25.4-153.4L25.4-153.4z"
				/>
			</g>
		</g>
		<g id="Layer_3_copy_4" style={{ display: 'none' }}>
			<path
				style={{
					display: 'inline',
					fill: '#FFFFFF',
					stroke: '#009EB7',
					strokeWidth: '9.1245',
					strokeMiterlimit: 10
				}}
				d="M-53.8,34.2
		c-1.2-5.1-1.8-10.5-1.8-15.9c0-39.9,32.3-72.2,72.2-72.2C51-53.9,79.8-29.8,87,2.5c7-3.6,14.8-5.6,23.1-5.6
		c28.1,0,50.8,22.7,50.8,50.8c0,3.3-0.3,6.6-0.9,9.7c1-0.1,1.9-0.1,2.9-0.1c23.3,0,42.1,18.9,42.1,42.1c0,17.4-10.6,32.4-25.7,38.8
		c0,0-3.3,2.5-27.4,1.9H-29.3c-30.8,0-55.8-25-55.8-55.8C-85,62.3-72.3,43.3-53.8,34.2z"
			/>
		</g>
		<g id="Layer_3_copy" style={{ display: 'none' }}>
			<path
				style={{
					display: 'inline',
					fill: '#FFFFFF',
					stroke: '#8DC63F',
					strokeWidth: 6,
					strokeMiterlimit: 10
				}}
				d="M49.4,18.9
		c0.8-3.4,1.2-6.9,1.2-10.5C50.5-17.8,29.3-39,3.1-39C-19.6-39-38.5-23.2-43.2-2c-4.6-2.3-9.7-3.7-15.2-3.7
		c-18.5,0-33.4,15-33.4,33.4c0,2.2,0.2,4.3,0.6,6.4c-0.6,0-1.3-0.1-1.9-0.1c-15.3,0-27.7,12.4-27.7,27.7c0,11.5,7,21.3,16.9,25.5
		c0,0,2.2,1.7,18,1.2H33.2c20.2,0,36.7-16.4,36.7-36.7C69.9,37.4,61.5,24.9,49.4,18.9z"
			/>
		</g>
	</SvgIcon>
);

export const EditIcon = (props: SvgIconProps) => (
	<SvgIcon viewBox="0 0 1000 1000" {...props}>
		<path
			d="M0,960.4C27.8,865,55.5,769.6,83.3,674.2c2.2-7.6,4.5-15.2,6.5-22.8c3.2-11.5,9.2-20.8,18.4-28.5
	c8.6-7.1,16.2-15.6,24.1-23.5C262.6,470.3,392.9,341.2,523.1,212c3-3,5.6-6.4,7.7-9c17.7,17.8,34.3,34.5,52.8,53.1
	c-2,1.4-5.9,3.4-8.9,6.4c-135,134.7-269.9,269.4-404.6,404.4c-4.8,4.9-8.8,11.6-10.7,18.2c-16.2,53.5-31.6,107.3-47.6,161
	c-2,6.7-1,10.9,4.1,15.5c8.3,7.5,15.6,16.1,24,23.5c2.9,2.5,8.4,4.4,11.9,3.5c53.8-15.4,107.5-31.1,161.1-47.3
	c4.6-1.4,8.9-5.3,12.5-9C462.3,695.8,599,559.1,735.7,422.5c2.6-2.6,4.8-5.5,6.3-7.2c19.4,19.4,38.2,38.2,58.8,58.8
	c-0.9,0.6-4.5,2.1-7,4.6C659,612.2,524,745.4,389.9,879.6c-18.7,18.8-39,30.4-64.1,37.5c-89,25.2-177.6,51.8-266.4,77.9
	c-5.9,1.7-11.9,3.3-17.8,5h-8c-4.3-1.9-8.7-3.6-13-5.6c-12-5.6-16.1-17-20.6-28.1L0,960.4L0,960.4z M268.9,794.7
	c-2.6-16.6-5.4-31.8-7.2-47c-0.9-7.2-3.8-10.1-10.8-11.4c-17.1-3.3-34-7.6-49.8-11.1c141.8-142.1,283.6-284.3,424.5-425.6
	c22.5,22.5,46,45.9,68.7,68.5C553.9,508.9,412.2,651,268.9,794.7z M677.3,64.1c12.8-12.5,25.6-25.8,39.3-38.2
	c39.3-35.4,90.5-34.6,128.8,2.8c42.3,41.2,84,82.9,125.2,125.2c39,39.9,38.4,93.3-0.5,133.1c-11.7,12-23.8,23.7-35,34.7
	C849.5,236.2,764,150.8,677.3,64.1z M573,158.5c19.4-18.2,39.3-36.9,58.2-54.7c89.6,89.6,178.7,178.8,268.6,268.7
	c-18.7,17.5-38.8,36.4-58.2,54.6L573,158.5L573,158.5z"
		/>
	</SvgIcon>
);

export const DeleteIcon = (props: SvgIconProps) => (
	<SvgIcon viewBox="0 0 1000 1000" {...props}>
		<path
			d="M799.7,277.2c-97.4-0.5-194.8-0.1-292.1-0.1c-103,0-206.1-0.7-309.1,0.4c-51.4,0.5-63.6,14-56.7,64.2
	c27.2,198.1,55.6,396,83.7,593.9c7.5,53,20.6,64.2,74.2,64.3c138.3,0.2,276.6,0.2,414.9,0.1c59.4,0,72.8-14.7,79.8-73.2
	c22.6-190.2,46.1-380.3,70.8-570.2C873.8,290.9,865.2,277.6,799.7,277.2L799.7,277.2z M346.1,895.4c-33.6,4.1-46.2-22.9-49.6-51.3
	c-16.3-136.9-31.6-274-47.4-410.9C246,406,254,383,282.3,378.3c31.2-5.2,46.4,17,50.1,44.7c10.7,80.9,20,162,29.6,243
	c6.6,55.9,12.9,111.8,19.3,167.7C384.3,863.1,380.1,891.3,346.1,895.4L346.1,895.4z M547,843.7c-0.1,27.9-11.7,53.2-42.4,52.7
	c-33-0.6-42-26.5-41.7-56.8c0.7-67.5,0.2-135.1,0.2-202.6h-0.7c0-67.5,0.3-135.1-0.1-202.6c-0.2-29,4.8-57.1,39.1-58.9
	c34.5-1.8,44.5,25.9,44.7,54.6C547.2,567.9,547.3,705.8,547,843.7z M763.9,437c-16.5,136.9-33.8,273.6-51.5,410.4
	c-3.6,27.6-18.3,51.3-48.7,46.9c-32.7-4.8-38.3-31.6-34.2-61.6c9.2-66.9,16.6-134,25.2-201s17.4-133.9,25.5-201
	c3.5-28.8,12-56,46.3-53.5C760.9,379.7,767.3,408.5,763.9,437L763.9,437z M424.5,86.5C441.3,2.6,450-6.1,506.3,2.7
	c95.8,15,98.3,18.1,85.8,111.4c74,13.1,148.1,27.3,222.6,39c32.3,5.1,61.3,12.6,55.8,52.6c-5.9,42.8-40.6,36.9-69.6,32.1
	C628.9,209,457,179,285.1,149.4c-37.4-6.5-74.7-14-112.3-19.6c-30.2-4.5-47.6-20.2-42.4-50.9c5.4-31.5,29.5-38.6,57.9-33.7
	C267.1,58.7,345.8,72.7,424.5,86.5L424.5,86.5z"
		/>
	</SvgIcon>
);

export const DeviceIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<style type="text/css">{`
            .st0{fill:none;stroke:currentColor;stroke-width:1.9642;stroke-miterlimit:10;}
            .st1{fill:none;stroke:currentColor;stroke-width:1.9766;stroke-miterlimit:10;}
        `}</style>
		<line className="st0" x1="14" y1="8.7" x2="14" y2="22.8" />
		<path
			className="st1"
			d="M13.6,8.7h5.9c0.6,0,1-0.5,1-1V5.2c0-0.6-0.5-1-1-1h-5.9c-0.6,0-1,0.5-1,1v2.5C12.6,8.3,13.1,8.7,13.6,8.7z"
		/>
		<line className="st0" x1="4.1" y1="7.6" x2="12.4" y2="7.6" />
		<path
			className="st0"
			d="M19.5,22.9H4.8c-0.6,0-1-0.5-1-1V2.3c0-0.6,0.5-1,1-1h5.3v0.8l9.4-0.8c0.6,0,1,0.5,1,1V22
	C20.5,22.5,20.1,22.9,19.5,22.9z"
		/>
	</SvgIcon>
);

export const PolicyIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			d="M23.4,14.4c-0.1,0-0.2,0-0.3-0.2c-1-1.9-2-3.9-3-5.8c0,0,0-0.1-0.1-0.1c0.4-0.1,0.8-0.2,1.2-0.4
	c0.5-0.2,0.7-0.8,0.5-1.3c-0.2-0.5-0.8-0.8-1.3-0.5c-0.9,0.4-1.8,0.4-2.6-0.1c-0.1,0-0.1-0.1-0.2-0.1c-1.3-0.7-2.7-1-4.2-1
	c-0.3,0-0.5,0-0.8,0c0.7-0.3,1.2-1,1.2-1.8c0-1.1-0.9-1.9-1.9-1.9S10.1,2,10.1,3c0,0.8,0.5,1.5,1.2,1.8c-0.2,0-0.4,0-0.7,0
	C9,4.9,7.4,5.3,6,6.1c-0.8,0.4-1.6,0.4-2.4,0C3,5.8,2.5,6,2.2,6.6C2,7.1,2.2,7.6,2.8,7.9C3.1,8.1,3.5,8.2,4,8.3
	C3.9,8.4,3.9,8.4,3.8,8.5c-1,1.9-1.9,3.7-2.9,5.6c-0.1,0.2-0.2,0.3-0.5,0.3c-0.2,0-0.4,0.1-0.5,0.2v0.2c0.4,1.4,1.4,2.1,2.7,2.5
	c1.7,0.5,3.4,0.5,5-0.3c0.6-0.3,1.1-0.7,1.5-1.2c0.2-0.3,0.3-0.6,0.4-0.9c0-0.3-0.1-0.5-0.3-0.4c-0.4,0-0.5-0.1-0.6-0.4
	c-0.9-1.8-1.9-3.6-2.8-5.4C5.6,8.3,5.6,8.3,6,8.2c0.7-0.1,1.4-0.3,2-0.6c0.7-0.3,1.3-0.5,2.1-0.6c0.3,0,0.3,0,0.3,0.3
	c0,3.9,0,7.7,0,11.6c0,0.2,0,0.3-0.2,0.3c-0.7,0.1-1.3,0.7-1.4,1.4c0,0.2-0.1,0.2-0.2,0.2c-0.5,0-0.9,0-1.3,0c-1,0-1.6,0.6-1.6,1.6
	h12.8c0-0.9-0.7-1.6-1.6-1.6c-0.5,0-0.9,0-1.4,0c-0.1,0-0.2,0-0.2-0.2c-0.1-0.7-0.7-1.3-1.4-1.4c-0.1,0-0.2,0-0.2-0.2
	c0-3.9,0-7.8,0-11.8C13.6,7,13.7,7,13.8,7c0.6,0.1,1.2,0.2,1.8,0.5c0.8,0.3,1.7,0.6,2.6,0.7c0.2,0,0.1,0.2,0.1,0.2
	c-0.7,1.3-1.4,2.6-2,3.9c-0.3,0.6-0.6,1.2-0.9,1.8c-0.1,0.1-0.1,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.3,0-0.4,0.2-0.3,0.4
	c0.1,0.7,0.6,1.2,1.1,1.7c0.8,0.6,1.6,0.9,2.6,1c1.3,0.2,2.7,0.1,3.9-0.5c0.7-0.3,1.2-0.8,1.6-1.4c0.1-0.2,0.2-0.5,0.3-0.8v-0.2
	C23.9,14.4,23.7,14.4,23.4,14.4L23.4,14.4z M7.2,14.4H2.4c0.8-1.5,1.6-3,2.4-4.5C5.6,11.4,6.4,12.9,7.2,14.4z M16.8,14.4
	c0.8-1.5,1.6-3,2.4-4.5c0.8,1.5,1.6,3,2.4,4.5H16.8z"
		/>
	</SvgIcon>
);

export const AssignPolicyIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M13.22 22.61c-.4.15-.8.29-1.22.39-5.16-1.26-9-6.45-9-12V5l9-4 9 4v6c0 .9-.11 1.78-.3 2.65-.81-.41-1.73-.65-2.7-.65-3.31 0-6 2.69-6 6 0 1.36.46 2.61 1.22 3.61zM19 20v2.99s-1.99.01-2 0V20h-3v-2h3v-3h2v3h3v2h-3z" />
	</SvgIcon>
);

export const ViewIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
	</SvgIcon>
);

export const CloneIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fill="currentColor"
			d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
		/>
	</SvgIcon>
);

export const FileCopyIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fill="currentColor"
			d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4H8c-1.1 0-1.99.9-1.99 2L6 21c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V11l-6-6zM8 21V7h6v5h5v9H8z"
		/>
	</SvgIcon>
);

export const ChangePasswordIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fill="currentColor"
			d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z"
		/>
	</SvgIcon>
);

export const NotSecureIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fill="currentColor"
			d="M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5M11,7H13V13H11M11,15H13V17H11"
		/>
	</SvgIcon>
);

export const OfflineStatusIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fill="currentColor"
			d="M19.43,19L21.5,21.11L20.12,22.5L18.03,20.41L15.91,22.53L14.5,21.11L16.61,19L14.5,16.86L15.88,15.47L18,17.59L20.12,15.47L21.55,16.9L19.43,19M21,11C21,11.9 20.9,12.78 20.71,13.65C20.13,13.35 19.5,13.15 18.81,13.05C18.93,12.45 19,11.83 19,11.22V6.3L12,3.18L5,6.3V11.22C5,15.54 8.25,20 12,21L12.31,20.91C12.5,21.53 12.83,22.11 13.22,22.62L12,23C6.84,21.74 3,16.55 3,11V5L12,1L21,5V11Z"
		/>
	</SvgIcon>
);

export const PendingStatusIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fill="currentColor"
			d="M18 12A6.41 6.41 0 0 1 20.87 12.67A11.63 11.63 0 0 0 21 11V5L12 1L3 5V11C3 16.55 6.84 21.74 12 23C12.35 22.91 12.7 22.8 13 22.68A6.42 6.42 0 0 1 11.5 18.5A6.5 6.5 0 0 1 18 12M18 14.5V13L15.75 15.25L18 17.5V16A2.5 2.5 0 0 1 20.24 19.62L21.33 20.71A4 4 0 0 0 18 14.5M18 21A2.5 2.5 0 0 1 15.76 17.38L14.67 16.29A4 4 0 0 0 18 22.5V24L20.25 21.75L18 19.5Z"
		/>
	</SvgIcon>
);

export const NoPolicyStatusIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fill="currentColor"
			d="M1,4.27L3,6.27V11C3,16.55 6.84,21.74 12,23C13.87,22.54 15.57,21.56 16.97,20.24L19.23,22.5L20.5,21.22L2.28,3L1,4.27M12,21C8.25,20 5,15.54 5,11.22V8.27L15.59,18.86C14.53,19.89 13.3,20.65 12,21M21,5V11C21,13.28 20.35,15.5 19.23,17.4L17.77,15.95C18.54,14.5 19,12.84 19,11.22V6.3L12,3.18L7.16,5.34L5.65,3.82L12,1L21,5Z"
		/>{' '}
	</SvgIcon>
);

export const NotAssessedStatusIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fill="currentColor"
			d="M21,11C21,16.55 17.16,21.74 12,23C6.84,21.74 3,16.55 3,11V5L12,1L21,5V11M12,21C15.75,20 19,15.54 19,11.22V6.3L12,3.18V21Z"
		/>
	</SvgIcon>
);

export const UserIcon = (props: SvgIconProps) => (
	<SvgIcon {...props} viewBox="0 0 1000 1000">
		<path
			fill="currentColor"
			d="M623.7,548.9c-3.6-1-7.1-1.9-10.6-2.7c-30.3,18.9-66,29.9-104.3,29.9c-38.8,0-75-11.2-105.5-30.7
	C323.5,563,263,636.6,261.1,715.9c-1,41.8-1.9,83.5-1.9,125.3c-0.1,38.9-2.9,40,17.1,45.3c0,0,255.7,59.7,465.1,0.6
	c11.1-4.6,17.4-10.2,17.1-23.8c-1-48.1,0.9-96.4-1-144.5C754.4,638.4,701.4,570.6,623.7,548.9z M318.2,547.8
	c15.9-11.8,34.1-19.4,52.6-28.3c-34.1-33.7-56-79.7-58.3-130.9c-1.1,0-2.3,0-3.5,0c-38.2,0-76.3-0.9-114.4,0.3
	c-45.5,1.5-84.4,13.8-116.1,37.8C63.8,438.4,2,497.1,0,652.9v76.7c63.9,20.5,129.3,34,195.9,42.5c21.9,2.8,30.2-1.4,29.6-25.9
	C223.2,665.1,251.5,597.3,318.2,547.8z M998,562.6c-3.2-64.6-35.5-113.2-90-146.5c-63.5-38.9-134.3-25.9-203-26.9
	c-2.5,51-24.3,96.8-58.3,130.4c31.8,13.3,59.7,30,82.8,54.2c47.3,49.6,66.2,109.2,62.1,176.7c-1,16.9,3,24.4,22.2,23
	c57.3-4.4,112.8-15.6,166.6-36.1c13.5-5.1,20-12.2,19.6-27.8C998.6,660.6,1000.4,611.5,998,562.6L998,562.6z M509.9,530.8
	c80-1.2,144.6-66.5,144.7-146.3c0-80.9-63.7-145.6-144.1-146C429,238,362.6,303.1,362.2,383.8C361.8,463.6,430.4,531.9,509.9,530.8
	L509.9,530.8z M256.8,380c3.1-0.3,10.5-0.4,17.5-1.8c14.1-2.9,27.4-6,38.5-12.2c4.3-61.8,37.1-115.7,85.3-148.6
	c-0.3-3.7-0.9-7.5-1.6-11.4c-14.5-73.4-86-127.2-156.8-118.6C161,97,102.2,162.9,106,237C110.2,317.7,173.2,379,256.8,380z
	 M705,372.1c2.2,0.8,4.5,1.6,7,2.3c92.8,25.6,185.2-45.2,184.4-142c-0.6-76.6-56.5-136.8-134.8-145.2
	c-69-7.4-138.3,44.5-154.2,115.2c-0.6,2.3-1,4.5-1.2,6.7C663.3,241.7,702.4,302.2,705,372.1L705,372.1z"
		/>
	</SvgIcon>
);

export const ReportsIcon = (props: SvgIconProps) => (
	<SvgIcon {...props} viewBox="0 0 1000 1000">
		<path d="M451.2 137.9c-214.6 0-388.7 173.99999999999997-388.7 388.70000000000005s174 388.79999999999995 388.7 388.79999999999995 388.7-174 388.7-388.79999999999995h-388.7v-388.70000000000005z m486.3 344.20000000000005c0-107.30000000000001-43.5-204.5-113.79999999999995-274.8l-274.9000000000001 274.8h388.70000000000005z m-437.1-397.70000000000005v388.6l274.80000000000007-274.8c-75.90000000000009-75.89999999999999-175.4000000000001-113.79999999999998-274.80000000000007-113.79999999999998z" />
	</SvgIcon>
);

export const BellIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			transform="rotate(20 12 12)"
			fill="currentColor"
			d="M21,19V20H3V19L5,17V11C5,7.9 7.03,5.17 10,4.29C10,4.19 10,4.1 10,4A2,2 0 0,1 12,2A2,2 0 0,1 14,4C14,4.1 14,4.19 14,4.29C16.97,5.17 19,7.9 19,11V17L21,19M14,21A2,2 0 0,1 12,23A2,2 0 0,1 10,21"
		/>
	</SvgIcon>
);

export const ViewPasswordIcon = (props: SvgIconProps) => (
	<SvgIcon {...props} viewBox="0 0 400 400">
		<path
			d="M178.301 19.657 C 138.500 30.361,117.188 61.967,117.188 110.289 L 117.188 132.421 104.233 133.101 C 88.526 133.925,79.806 138.098,72.726 148.178 L 67.969 154.950 66.890 241.147 C 65.655 339.831,65.725 341.217,72.460 351.266 C 82.164 365.746,84.575 366.334,136.513 366.886 L 181.621 367.366 171.975 358.435 C 161.997 349.198,148.438 325.556,148.438 317.397 C 148.438 311.835,157.185 292.216,164.907 280.458 L 171.927 269.771 169.365 263.064 C 157.439 231.836,192.459 203.828,219.316 223.115 L 226.357 228.172 237.788 224.346 C 262.877 215.948,298.978 214.780,320.313 221.677 C 325.898 223.482,331.033 224.969,331.724 224.980 C 332.414 224.991,332.766 209.238,332.505 189.974 L 332.031 154.947 327.274 148.177 C 320.193 138.097,311.473 133.925,295.767 133.101 L 282.813 132.421 282.813 110.289 C 282.813 95.456,282.023 85.127,280.420 78.971 C 269.004 35.143,222.075 7.885,178.301 19.657 M221.094 54.812 C 241.802 64.639,248.794 78.076,249.689 109.766 L 250.341 132.813 200.170 132.813 L 150.000 132.813 150.000 112.555 C 150.000 63.812,183.213 36.836,221.094 54.812 M261.719 252.234 C 229.656 259.768,203.753 278.530,189.394 304.620 L 182.982 316.272 188.683 327.277 C 224.986 397.347,328.760 401.655,373.378 334.945 C 383.622 319.629,383.980 317.834,378.825 307.611 C 358.086 266.482,305.968 241.837,261.719 252.234 M300.850 279.043 C 341.545 298.355,326.179 360.242,281.368 357.507 C 258.713 356.125,243.875 341.287,242.493 318.632 C 240.609 287.757,272.961 265.809,300.850 279.043 M275.172 302.095 C 266.132 307.312,264.362 320.853,271.755 328.245 C 282.921 339.411,302.321 329.266,299.416 313.781 C 297.445 303.279,284.192 296.891,275.172 302.095 "
			stroke="none"
			fill="currentColor"
		/>
	</SvgIcon>
);

export const AddToDeviceGroupIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fill="currentColor"
			d="M2,16H10V14H2M18,14V10H16V14H12V16H16V20H18V16H22V14M14,6H2V8H14M14,10H2V12H14V10Z"
		/>
	</SvgIcon>
);

export const ClearSelectedIcon = (props: SvgIconProps) => (
	<SvgIcon {...props}>
		<path
			fill="currentColor"
			d="M4 20H18V22H4C2.9 22 2 21.11 2 20V6H4V20M20.22 2H7.78C6.8 2 6 2.8 6 3.78V16.22C6 17.2 6.8 18 7.78 18H20.22C21.2 18 22 17.2 22 16.22V3.78C22 2.8 21.2 2 20.22 2M19 13.6L17.6 15L14 11.4L10.4 15L9 13.6L12.6 10L9 6.4L10.4 5L14 8.6L17.6 5L19 6.4L15.4 10L19 13.6Z"
		/>
	</SvgIcon>
);

export const ExpiredIcon = (props: Omit<MdiIconProps, 'path'>) => (
	<MdiIcon className="MuiSvgIcon-root" path={mdiClockAlert} {...props} />
);

export const LogoutIcon = (props: SvgIconProps) => (
	<SvgIcon {...props} viewBox="0 0 48 48">
		<g>
			<path
				d="M28.9,28.5c-0.5,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.7-0.4,1.1v7h-17V11h17v7c0,0.5,0.1,0.8,0.4,1.1c0.3,0.3,0.7,0.4,1.1,0.4
                  c0.5,0,0.8-0.1,1.1-0.4c0.3-0.3,0.4-0.7,0.4-1.1v-7c0-0.8-0.3-1.5-0.9-2.1C28.9,8.3,28.2,8,27.4,8h-17C9.6,8,8.9,8.3,8.3,8.9
                  C7.7,9.5,7.4,10.2,7.4,11v26c0,0.8,0.3,1.5,0.9,2.1c0.6,0.6,1.3,0.9,2.1,0.9h17c0.8,0,1.5-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1v-7
                  c0-0.5-0.1-0.8-0.4-1.1C29.7,28.6,29.3,28.5,28.9,28.5z"
			/>
			<path
				d="M42.2,24c0-0.4-0.2-0.7-0.4-1c0,0,0-0.1-0.1-0.1L35,16.3c-0.3-0.3-0.7-0.5-1.1-0.5c-0.4,0-0.8,0.2-1.1,0.5
                c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.4,0.1,0.7,0.4,1l4.1,4.1H16.4c-0.5,0-0.8,0.1-1.1,0.4c-0.3,0.3-0.4,0.6-0.4,1.1s0.1,0.8,0.4,1.1
                c0.3,0.3,0.7,0.4,1.1,0.4h20.5l-4.1,4.1c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.4,0.1,0.8,0.4,1.1c0.3,0.2,0.6,0.4,1.1,0.4
                c0.4,0,0.7-0.1,1-0.4l6.8-6.8C42,24.7,42.2,24.4,42.2,24z"
			/>
		</g>
	</SvgIcon>
);

export const LicensingIcon = (props: SvgIconProps) => (
	<SvgIcon {...props} viewBox="0 0 20 20">
		<g id="Layer_1">
			<g>
				<rect x="-0.1" y="-0.1" style={{ fill: 'none' }} width="20.3" height="20.3" />
			</g>
			<g>
				<g>
					<path
						d="M18.7,8.2l-1.9-2.1l0.3-2.8l-2.8-0.6l-1.4-2.4l-2.6,1.1L7.7,0.2L6.2,2.6L3.5,3.2l0.3,2.8L1.9,8.2l1.9,2.1l-0.3,2.8
				l2.8,0.6l1.4,2.4l2.6-1.1l2.6,1.1l1.4-2.4l2.8-0.6l-0.3-2.8L18.7,8.2z M15.7,9.3l-0.4,0.5l0.1,0.7l0.1,1.5L14,12.3l-0.6,0.1
				L13,13l-0.8,1.3l-1.4-0.6l-0.6-0.3l-0.6,0.3l-1.4,0.6L7.6,13l-0.3-0.6l-0.6-0.1L5.1,12l0.1-1.5l0.1-0.7L4.9,9.3l-1-1.1l1-1.1
				l0.4-0.5L5.3,5.9L5.1,4.4l1.5-0.3L7.2,4l0.3-0.6l0.8-1.3l1.4,0.6L10.3,3l0.6-0.3l1.4-0.6L13,3.4L13.3,4L14,4.1l1.5,0.3l-0.1,1.5
				l-0.1,0.7l0.4,0.5l1,1.1L15.7,9.3z"
					/>
					<polygon style={{ display: 'none' }} points="8.8,9.5 7.1,7.8 5.9,8.9 8.8,11.8 14.4,6.2 13.3,5" />
				</g>
			</g>
		</g>
		<g id="Layer_2">
			<polygon points="3.3,11.6 0,15.7 3.4,15.9 4,19.8 7.2,16.1 6.5,14.8 4.5,17.1 4.2,14.9 2.5,14.8 3.6,13.5 3.2,13.4" />
			<polygon points="17.2,11.6 20,15.3 17.1,15.9 16.7,19.7 13.3,16 14,14.8 16,17 16.3,14.9 17.9,14.6 16.9,13.5 17.3,13.3" />
		</g>
	</SvgIcon>
);

export const ReinviteIcon = (props: SvgIconProps) => (
	<SvgIcon {...props} viewBox="0 0 21 21">
		<path
			fill="currentColor"
			id="running_with_errors_FILL0_wght400_GRAD0_opsz24"
			d="M12,22a9.732,9.732,0,0,1-3.9-.788A9.978,9.978,0,0,1,2.788,15.9,9.732,9.732,0,0,1,2,12a9.732,9.732,0,0,1,.788-3.9A9.984,9.984,0,0,1,8.1,2.787,9.743,9.743,0,0,1,12,2a9.9,9.9,0,0,1,7.55,3.45L12,13V4A7.721,7.721,0,0,0,6.325,6.325,7.721,7.721,0,0,0,4,12a7.721,7.721,0,0,0,2.325,5.675A7.721,7.721,0,0,0,12,20a7.923,7.923,0,0,0,3.3-.712A7.628,7.628,0,0,0,18,17.25V20a11.029,11.029,0,0,1-2.85,1.475A9.61,9.61,0,0,1,12,22Zm8-4V10h2v8Zm1,4a.984.984,0,1,1,.712-.288A.965.965,0,0,1,21,22Z"
			transform="translate(-2 -2)"
		/>
	</SvgIcon>
);

export const FilterIcon = (props: SvgIconProps) => (
	<SvgIcon {...props} viewBox="0 0 24 24">
		<g>
			<path d="M0,0h24 M24,24H0" fill="none" />
			<path d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z" />
			<path d="M0,0h24v24H0V0z" fill="none" />
		</g>
	</SvgIcon>
);

export const PencilIcon = (props: SvgIconProps) => (
	<SvgIcon {...props} viewBox="0 0 20 20">
		<path
			id="action-edit"
			d="M.3,16.843c.52-1.56.966-3.194,1.411-4.828a.67.67,0,0,0,.074-.371,1.2,1.2,0,0,1,.3-.52,1.969,1.969,0,0,1,.446-.371c2.228-2.228,4.456-4.382,6.685-6.61l.149-.149.891.891a.259.259,0,0,1-.149.074L3.2,11.867a.563.563,0,0,0-.149.3c-.3.891-.52,1.857-.817,2.748a.447.447,0,0,0,.074.3,1.969,1.969,0,0,0,.446.371.273.273,0,0,0,.223.074c.891-.3,1.857-.52,2.748-.817.074,0,.149-.074.223-.149l6.982-6.982C13,7.633,13,7.633,13,7.559l.966.966a.23.23,0,0,0-.149.074c-2.3,2.3-4.605,4.531-6.908,6.833A2.8,2.8,0,0,1,5.8,16.1c-1.485.446-3.045.891-4.531,1.337-.074,0-.223.074-.3.074H.894c-.074,0-.149-.074-.223-.074-.223-.074-.3-.3-.371-.446v-.149Zm4.605-2.822c-.074-.3-.074-.52-.149-.817,0-.149-.074-.149-.149-.223-.3-.074-.594-.149-.817-.223C6.242,10.307,8.619,7.931,11,5.479l1.188,1.188C9.733,9.193,7.356,11.644,4.905,14.021Zm6.982-12.4.668-.668a1.549,1.549,0,0,1,2.228.074c.743.668,1.411,1.411,2.154,2.154a1.572,1.572,0,0,1,0,2.3l-.594.594C14.784,4.514,13.3,3.1,11.887,1.617ZM10.1,3.251a11.835,11.835,0,0,0,.966-.966c1.485,1.56,3.045,3.045,4.605,4.605a11.834,11.834,0,0,0-.966.966L10.1,3.251Z"
			transform="translate(-0.3 -0.512)"
		/>
	</SvgIcon>
);

export const GenerateUsernameIcon = (props: SvgIconProps) => (
	<SvgIcon {...props} viewBox="0 0 24 24">
		<g id="Group_3946" data-name="Group 3946" transform="translate(-16 4)">
			<g id="Group_3943" data-name="Group 3943" transform="translate(-143 -373)">
				<path
					id="_Color"
					data-name=" ↳Color"
					d="M11,16a7.943,7.943,0,0,1-4.26-1.24L8.2,13.3A5.886,5.886,0,0,0,11,14a6.007,6.007,0,0,0,6-6H14l4-4,4,4H19A8.01,8.01,0,0,1,11,16ZM4,12H4L0,8H3A7.986,7.986,0,0,1,15.26,1.24L13.8,2.7A5.886,5.886,0,0,0,11,2,6.007,6.007,0,0,0,5,8H8L4,12Z"
					transform="translate(160 373)"
					fill="#306431"
				/>
			</g>
			<rect
				id="Rectangle_2155"
				data-name="Rectangle 2155"
				width="24"
				height="24"
				transform="translate(16 -4)"
				fill="none"
			/>
		</g>
	</SvgIcon>
);
export const GeneratePasswordIcon = (props: SvgIconProps) => (
	<SvgIcon {...props} viewBox="0 0 24 24">
		<g id="Group_3945" data-name="Group 3945" transform="translate(-16)">
			<g id="Group_3943" data-name="Group 3943" transform="translate(-143 -373)">
				<path
					id="_Color"
					data-name=" ↳Color"
					d="M11,16a7.943,7.943,0,0,1-4.26-1.24L8.2,13.3A5.886,5.886,0,0,0,11,14a6.007,6.007,0,0,0,6-6H14l4-4,4,4H19A8.01,8.01,0,0,1,11,16ZM4,12H4L0,8H3A7.986,7.986,0,0,1,15.26,1.24L13.8,2.7A5.886,5.886,0,0,0,11,2,6.007,6.007,0,0,0,5,8H8L4,12Z"
					transform="translate(160 373)"
					fill="#306431"
				/>
				<path
					id="password_FILL1_wght400_GRAD0_opsz24"
					d="M3.022,12.6,1.8,11.891l.8-1.411H1V9.069H2.6L1.8,7.705,3.022,7l.8,1.364L4.621,7l1.223.705-.8,1.364h1.6V10.48h-1.6l.8,1.411L4.621,12.6l-.8-1.411Zm7.524,0-1.223-.705.8-1.411h-1.6V9.069h1.6l-.8-1.364L10.546,7l.8,1.364L12.145,7l1.223.705-.8,1.364h1.6V10.48h-1.6l.8,1.411-1.223.705-.8-1.411Zm7.524,0-1.223-.705.8-1.411h-1.6V9.069h1.6l-.8-1.364L18.07,7l.8,1.364L19.669,7l1.223.705-.8,1.364h1.6V10.48h-1.6l.8,1.411-1.223.705-.8-1.411Z"
					transform="translate(159.654 384.4)"
					fill="#306431"
				/>
			</g>
			<rect
				id="Rectangle_2154"
				data-name="Rectangle 2154"
				width="24"
				height="24"
				transform="translate(16)"
				fill="none"
			/>
		</g>
	</SvgIcon>
);
export const OneUserIcon = () => (
	<SvgIcon>
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25.238 25.238">
			<path
				id="Subtraction_4"
				data-name="Subtraction 4"
				d="M14718.617,18228.238a12.622,12.622,0,1,1,8.926-3.7A12.524,12.524,0,0,1,14718.617,18228.238Zm-3.271-8.92h0v2h7.425v-2h-2.461v-9.4h-1.46l-3.344,1.953v2.191l2.306-1.359v6.611Z"
				transform="translate(-14706.001 -18203)"
				fill="#1f1f1f"
				opacity="0.996"
			/>
		</svg>
	</SvgIcon>
);

export const InfiniteUsersIcon = () => (
	<SvgIcon>
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25.238 25.238">
			<g id="Group_1277" data-name="Group 1277" transform="translate(-986.671 -2466.383)">
				<path
					id="Subtraction_1"
					data-name="Subtraction 1"
					d="M14718.617,18228.238a12.622,12.622,0,1,1,8.926-3.7A12.524,12.524,0,0,1,14718.617,18228.238Zm2.544-11.541h0l-1.354,1.221,1.29,1.168a4.091,4.091,0,0,0,1.249.758,3.961,3.961,0,0,0,1.455.266,4.492,4.492,0,1,0,0-8.984,4.019,4.019,0,0,0-2.7,1.029l-6.176,5.572a2.26,2.26,0,0,1-.686.42,2.114,2.114,0,0,1-.8.152,2.679,2.679,0,1,1,0-5.357,2.14,2.14,0,0,1,.8.15,2.263,2.263,0,0,1,.686.416l1.158,1.035,1.354-1.223-1.29-1.166a4.062,4.062,0,0,0-1.249-.764,4.161,4.161,0,0,0-1.454-.266,4.492,4.492,0,1,0,0,8.984,4.042,4.042,0,0,0,1.454-.266,4.1,4.1,0,0,0,1.249-.758l6.176-5.578a2.292,2.292,0,0,1,.687-.416,2.132,2.132,0,0,1,.8-.15,2.679,2.679,0,1,1,0,5.357,2.106,2.106,0,0,1-.8-.152,2.241,2.241,0,0,1-.687-.42l-1.157-1.029Z"
					transform="translate(-13719.33 -15736.617)"
					fill="#1f1f1f"
					opacity="0.996"
				/>
			</g>
		</svg>
	</SvgIcon>
);
