import React, { useState, useEffect } from 'react';
import { useDispatch } from 'app/modules/react-redux';
import * as Actions from 'app/store/actions';

type Props = {
	children: React.ReactNode;
};

const PurgeStateWrapper = ({ children }: Props) => {
	const dispatch = useDispatch();
	const [click, setClick] = useState(0);

	useEffect(() => {
		if (click > 0 && click % 8 === 0) {
			dispatch(Actions.purgeState());
			window.location.reload();
		}
	}, [click, dispatch]);

	return <span onClick={() => setClick(click + 1)}>{children}</span>;
};

export default PurgeStateWrapper;
