import {
	useSelector as useSelectorOriginal,
	TypedUseSelectorHook,
	useDispatch as useDispatchOriginal
} from 'react-redux';
import type { RootState, AppDispatch } from 'app/store';

// define types
const useSelector: TypedUseSelectorHook<RootState> = useSelectorOriginal;
const useDispatch = () => useDispatchOriginal<AppDispatch>();

export * from 'react-redux';

// override method
export { useSelector, useDispatch };
