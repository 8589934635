import React from 'react';

const TermLicensesPageConfig = {
	settings: {
		layout: {
			config: {
				adminPage: true
			}
		}
	},
	routes: [
		{
			path: ['/admin/term-licenses'],
			exact: true,
			component: React.lazy(() => import('./TermLicensesPage'))
		}
	]
};

export default TermLicensesPageConfig;
