// Internet Explorer 11 requires polyfills and partially supported by this project.
// import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-muli';
import './i18n';
import './react-chartjs-2-defaults';
import './styles/index.css';
import App from 'app/App';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';

// this logic is repeated in `index.html` which renders a "browser not supported" bar
const modernBrowser = !!(window.Promise && window.Promise.allSettled);

const container = document.getElementById('root');
const root = createRoot(container);

if (modernBrowser) {
	root.render(<App />);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
