import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { red } from '@mui/material/colors';
import { Typography, Popover, IconButton, Badge, Button, Box, Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'app/modules/react-redux';
import { getDevices } from 'app/store/reducers';
import { Device } from 'app/store/types';
import { TLog } from 'app/utils/helpers';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	notificationPopup: {
		color: theme.palette.common.white,
		backgroundColor: red[500],
		width: 'calc(100vw - 280px - 20px)',
		padding: '1.2rem 1.2rem 1.2rem 9.6rem'
	},
	errorIcon: {
		fontSize: '4.8rem',
		position: 'absolute',
		top: '50%',
		left: '4.8rem',
		transform: 'translate(-50%, -50%)'
	},
	closeButtonWrapper: {
		position: 'absolute',
		top: 0,
		right: 0,
		margin: '0.2rem'
	}
}));

const LightTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11
	}
}))(Tooltip);

const Notification = () => {
	const { t } = useTranslation();
	const classes = useStyles();

	const devices = useSelector(getDevices);

	const notSecureDevices = devices.filter(device => device.secureStatus === 'fail');

	const previouslyAcknowledgedDeviceNotifications = JSON.parse(
		localStorage.getItem('acknowledgedDeviceNotifications') ?? '[]'
	) as Device['serial'][];

	const savedAcknowledgedDeviceNotifications = previouslyAcknowledgedDeviceNotifications.filter(deviceSerial =>
		notSecureDevices.some(({ serial }) => serial === deviceSerial)
	);

	const [acknowledgedDeviceNotifications, setAcknowledgedDeviceNotifications] = useState<Device['serial'][]>(
		// all of the locally stores devices that are still in the notSecureDevices list
		savedAcknowledgedDeviceNotifications
	);
	const savedAcknowledgedDeviceNotificationsDependency = JSON.stringify(savedAcknowledgedDeviceNotifications);
	useEffect(() => {
		setAcknowledgedDeviceNotifications(savedAcknowledgedDeviceNotifications);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [savedAcknowledgedDeviceNotificationsDependency]); // get an infinite render if `savedAcknowledgedDeviceNotifications` is added

	const unacknowledgedNotSecureDevicesNotifications = notSecureDevices.filter(
		device => !acknowledgedDeviceNotifications.includes(device.serial)
	);

	const [activeNotification, setActiveNotification] = React.useState(0);

	const handleBack = () => {
		setActiveNotification(prevActiveNotification => prevActiveNotification - 1);
	};

	const handleNext = () => {
		setActiveNotification(prevActiveNotification => prevActiveNotification + 1);
	};

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const saveAcknowledgedDeviceNotifications = () => {
		const newAcknowledgedDeviceNotifications = [
			...acknowledgedDeviceNotifications,
			unacknowledgedNotSecureDevicesNotifications[activeNotification].serial
		];
		localStorage.setItem('acknowledgedDeviceNotifications', JSON.stringify(newAcknowledgedDeviceNotifications));
		setAcknowledgedDeviceNotifications(newAcknowledgedDeviceNotifications);
	};

	const handleAcknowledge = () => {
		saveAcknowledgedDeviceNotifications();
		setActiveNotification(prevActiveNotification =>
			Math.min(prevActiveNotification, unacknowledgedNotSecureDevicesNotifications.length - 1 - 1)
		);
	};

	if (unacknowledgedNotSecureDevicesNotifications.length === 0) {
		return (
            <LightTooltip title={t('notification:no new notifications') ?? ''}>
				<span>
					<IconButton className="w-64 h-64 text-white" disabled size="large">
						<NotificationsIcon />
					</IconButton>
				</span>
			</LightTooltip>
        );
	}

	const open = !!anchorEl && unacknowledgedNotSecureDevicesNotifications.length !== 0;

	return <>
        <IconButton className="w-64 h-64 text-white" onClick={handleClick} size="large">
            <Badge color="error" badgeContent={unacknowledgedNotSecureDevicesNotifications.length}>
                <NotificationsIcon />
            </Badge>
        </IconButton>
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
        >
            <div className={classes.notificationPopup}>
                <ErrorIcon className={classes.errorIcon} />
                <div className="mb-8 flex items-center">
                    <Typography className="pr-16" variant="h6">
                        {t('notification:device not secure')}
                    </Typography>
                    <div
                        className={clsx(
                            {
                                'opacity-0 pointer-events-none':
                                    unacknowledgedNotSecureDevicesNotifications.length <= 1
                            },
                            'flex items-center'
                        )}
                    >
                        <Typography className="pr-16" variant="h6">
                            <span className="font-normal">
                                {activeNotification + 1}/{unacknowledgedNotSecureDevicesNotifications.length}
                            </span>
                        </Typography>
                        <div className="pr-16">
                            <IconButton
                                color="inherit"
                                onClick={handleBack}
                                disabled={activeNotification === 0}
                                size="large">
                                <KeyboardArrowLeft />
                            </IconButton>
                            <IconButton
                                color="inherit"
                                onClick={handleNext}
                                disabled={
                                    activeNotification === unacknowledgedNotSecureDevicesNotifications.length - 1
                                }
                                size="large">
                                <KeyboardArrowRight />
                            </IconButton>
                        </div>
                    </div>
                </div>
                <Box display="flex" justifyContent="space-between">
                    <Typography>
                        <TLog
                            log={{
                                messageKey: 'deviceFailedAssessment',
                                info: {
                                    deviceName:
                                        unacknowledgedNotSecureDevicesNotifications[activeNotification].name,
                                    deviceId: unacknowledgedNotSecureDevicesNotifications[activeNotification].serial
                                }
                            }}
                        />
                    </Typography>
                    <Button className="ml-16" color="inherit" onClick={handleAcknowledge}>
                        {t('notification:dismiss')}
                    </Button>
                </Box>
                <div className={classes.closeButtonWrapper}>
                    <IconButton onClick={handleClose} color="inherit" size="large">
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
        </Popover>
    </>;
};

export default Notification;
