import { TextField, TextFieldProps } from '@mui/material';
import React, { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordTextField = (props: TextFieldProps) => {
	const [showPassword, setShowPassword] = useState(false);

	return (
        <TextField
			type={showPassword ? 'text' : 'password'}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton onClick={() => setShowPassword(val => !val)} size="large">
							{showPassword ? <Visibility /> : <VisibilityOff />}
						</IconButton>
					</InputAdornment>
				)
			}}
			{...props}
		/>
    );
};

export default PasswordTextField;
