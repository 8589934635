import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Logo from 'app/fuse-layouts/shared-components/Logo';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import * as Actions from 'app/store/actions';
import { getCookieSettings, getCookieSettingsAcknowledged } from 'app/store/reducers';
import { useDispatch, useSelector } from 'app/modules/react-redux';
import { useTranslation } from 'react-i18next';

//
// Styles
//

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(0.6),
			color: '#FFFFFF',
		},
		button: {
			marginRight: '14px',
			textTransform: 'capitalize',
			width: '276px',
		},
		dialogContent: {
			paddingTop: '1.5em',
		},
		logoBackground: {
			backgroundColor: theme.palette.secondary.main,
		},
	}),
);

//
// Components
//
const CookieSettingsDescription = () => {
	const { t } = useTranslation();

	return (
		<DialogContent>
			<Grid item xs container direction="column" spacing={2}>
				<Grid item xs>
					<Typography gutterBottom variant="h6">
						{t('Cookie Settings')}
					</Typography>
					<Typography gutterBottom color="textSecondary">
						{t('cookie consent:description 1')}
					</Typography>
					<Typography gutterBottom color="textSecondary">
						{t('cookie consent:description 2')}
					</Typography>
					<Typography gutterBottom color="textSecondary">
						{t('cookie consent:description 3')}
					</Typography>
				</Grid>
			</Grid>
		</DialogContent>
	);
};

const CookieSettingsList = () => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<DialogContent className={classes.dialogContent}>
			<Accordion defaultExpanded>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Paper elevation={0}>
						<Grid container spacing={3}>
							<Grid item xs={10} sm container>
								<Grid item xs container direction="column" spacing={2}>
									<Grid item xs>
										<Typography gutterBottom variant="subtitle1">
											<strong>{t('cookie consent:technically necessary subtitle')}</strong>
										</Typography>
										<Typography variant="body2" color="textSecondary">
											{t('cookie consent:technically necessary text')}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={2} alignContent="center" container spacing={2}>
								<Grid item>
									<FormControlLabel
										disabled
										className="mt-4 mb-8 mr-8"
										label=""
										onClick={event => event.stopPropagation()}
										control={
											<Switch
												checked
												id="cookieSettingsSwitch"
												name="cookieSettingsSwitch"
												onChange={undefined}
											/>
										}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				</AccordionSummary>
				<Divider />
				<AccordionDetails>
					<Grid item xs container direction="column" spacing={2}>
						<Grid item xs>
							<Typography gutterBottom variant="subtitle1">
								<strong>{t('cookie consent:session subtitle')}</strong>
							</Typography>
							<Typography variant="body2" color="textSecondary" gutterBottom>
								{t('cookie consent:session text 1')}
							</Typography>
							<Typography variant="body2" color="textSecondary" gutterBottom>
								{t('cookie consent:session text 2')}
							</Typography>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>
		</DialogContent>
	);
};

const Footer = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(Actions.toggleCookieSettingsDialog(false));
	};

	const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		dispatch(Actions.acknowledgeCookieNagbar());
		handleClose();
	};

	return (
		<DialogActions>
			<Button
				className={classes.button}
				onClick={handleOnClick}
				color="secondary"
				variant="contained"
				type="submit"
			>
				{t('Accept')}
			</Button>
		</DialogActions>
	);
};

const Header = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const showCookieSettings = useSelector(getCookieSettings);

	const handleClose = () => {
		dispatch(Actions.toggleCookieSettingsDialog(false));
	};

	return (
        <DialogTitle id="alert-dialog-title" className={classes.logoBackground}>
			<Box color="secondary">
				<div className="flex flex-1 mx-8">
					<Logo />
				</div>
			</Box>
			{showCookieSettings ? (
				<IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}
                    size="large">
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
    );
};

//
// Main Component
//

const CookieSettingsDialog = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const cookieAcknowledged = useSelector(getCookieSettingsAcknowledged);
	const showCookieSettings = useSelector(getCookieSettings);
	const showDialog = !cookieAcknowledged || showCookieSettings;

	const handleClose = () => {
		dispatch(Actions.toggleCookieSettingsDialog(false));
	};

	return (
		<Dialog
			open={showDialog}
			onClose={handleClose}
			maxWidth="sm"
			aria-labelledby="Accept"
			aria-describedby="Accept"
		>
			<Header />
			<Divider />
			<CookieSettingsDescription />
			<Divider />
			<CookieSettingsList />
			{cookieAcknowledged ? null : <Footer />}
		</Dialog>
	);
};

export default CookieSettingsDialog;
