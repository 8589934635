import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';

type Props = ButtonProps & {
	loading?: boolean;
	done?: boolean;
};

const LoadingButton = ({ loading, done, ...props }: Props) => {
	if (done) {
		return (
			<Button {...props}>
				<CheckIcon />
			</Button>
		);
	}
	if (loading) {
		return (
			<Button {...props} disabled style={{ position: 'relative' }}>
				<span style={{ visibility: 'hidden' }}>{props.children}</span>
				<CircularProgress
					size={24}
					style={{
						position: 'absolute'
						// top: '50%',
						// left: '50%',
						// transform: 'translate(-50%, -50%)'
					}}
				/>
			</Button>
		);
	}
	return <Button {...props} />;
};

export default LoadingButton;
