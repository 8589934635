import localForage from 'localforage';
import _ from '@lodash';
import { persistReducer } from 'redux-persist';
import type { RootState } from 'app/store';
import { LogsState, LogsActionTypes } from 'app/store/types';

const initialState: LogsState = {};

const logsReducer = (state = initialState, action: LogsActionTypes) => {
	switch (action.type) {
		case 'GET_LICENSE_GROUP_DATA_SUCCESS': {
			const {
				licenseGroupId,
				data: { logs }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					byId: logs
				}
			};
		}
		case 'LOGGED_OUT_USER':
		case 'PURGE_STATE':
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'logs',
		storage: localForage
	},
	logsReducer
);

// Selectors
export const getLogs = ({ app: { selectedLicenseGroupId }, logs }: RootState) =>
	Object.values(logs[selectedLicenseGroupId].byId);
