import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import * as Actions from 'app/store/actions';
import React from 'react';
import { useDispatch } from 'app/modules/react-redux';

function NavbarMobileToggleButton(props) {
	const dispatch = useDispatch();

	return (
        <IconButton
            className={props.className}
            onClick={ev => dispatch(Actions.navbarToggleMobile())}
            color="inherit"
            disableRipple
            size="large">
			{props.children}
		</IconButton>
    );
}

NavbarMobileToggleButton.defaultProps = {
	children: <Icon>menu</Icon>
};

export default NavbarMobileToggleButton;
