import localForage from 'localforage';
import _ from '@lodash';
import { persistReducer } from 'redux-persist';
import type { RootState } from 'app/store';
import { UsersState, UsersActionTypes } from 'app/store/types';

const initialState: UsersState = {};

const usersReducer = (state = initialState, action: UsersActionTypes) => {
	switch (action.type) {
		case 'GET_LICENSE_GROUP_DATA_SUCCESS': {
			const {
				licenseGroupId,
				data: { users, pendingUsers }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					byId: users,
					// actually keyed by email despite the name
					pendingById: pendingUsers
				}
			};
		}
		case 'LOGGED_OUT_USER':
		case 'PURGE_STATE':
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'users',
		storage: localForage
	},
	usersReducer
);

// Selectors
export const getAllUsers = ({ app: { selectedLicenseGroupId }, users }: RootState) =>
	Object.values({
		...users[selectedLicenseGroupId].byId,
		...users[selectedLicenseGroupId].pendingById
	});

export const getUsers = ({ app: { selectedLicenseGroupId }, users }: RootState) =>
	Object.values(users[selectedLicenseGroupId].byId);

export const getUsersById = ({ app: { selectedLicenseGroupId }, users }: RootState) =>
	users[selectedLicenseGroupId].byId;

export const getPendingUsersById = ({ app: { selectedLicenseGroupId }, users }: RootState) =>
	users[selectedLicenseGroupId].pendingById;
export const getAppManagedUsers = ({ app: { selectedLicenseGroupId }, users }: RootState) =>
	Object.values(users[selectedLicenseGroupId].byId).filter(user => user.vaultType === 'app');

// HACK::assume oldest created user is the owner
export const getOwnerUser = (state: RootState) => _.orderBy(getUsers(state), ['dateAdded'])[0];

// CHANGE ME::different variable name? Maybe needs to be moved to LicenseGroups
// Checks if a user in the current selected tenant needs help.
export const getGroupVaultRecovery = (state: RootState) =>
	_.filter(getUsers(state) as ReturnType<typeof getUsers> | undefined, user => user?.vaultReset);
export const getUser = (id: unknown) => (state: RootState) =>
	_.find(getUsers(state) as ReturnType<typeof getUsers> | undefined, user => user.id === id);
export const getUserVaultType = (id: string) => (state: RootState) =>
	_.find(getUsers(state) as ReturnType<typeof getUsers> | undefined, user => user.id === id)?.vaultType;
