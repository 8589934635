import axios from 'app/client';
import _ from '@lodash';
import { responseError, responseErrors } from 'app/utils/helpers';
import { reverseParseJsonHack } from 'app/utils/hacks';
import { getAdminPasswordByPolicyId, getDevicesByDeviceGroup, getPolicyById, getSelectedLicenseGroupId, getUserVault, getSelectedLicenseGroupData } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { Policy, DeviceGroup } from 'app/store/types';
import { Crypto } from '@sec/shield-guard-password-management';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';
import * as policyActions from './policies.actions';
import { createChangeAdminPasswordTask } from './devices.actions';

export const createDeviceGroup = ({
	name,
	policyId
}: {
	name: string;
	policyId: Policy['id'] | undefined;
}): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	const data = reverseParseJsonHack({
		tenantId: licenseGroupId,
		name,
		policyId: policyId ?? null
	});

	try {
		const response = await axios.post(`/api/v1/devicegroup`, data);
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('failed to create device group', 'warning'));
		} else {
			dispatch(appActions.alert('device group created', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const editDeviceGroup = (
	{
		id,
		name,
		policyId
	}: {
		id: DeviceGroup['id'];
		name: string;
		policyId: Policy['id'] | undefined;
	},
	rotatePassword = true
): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const policy = policyId ? getPolicyById(policyId)(getState()) : undefined;
	const licenseGroupData = getSelectedLicenseGroupData(getState());

	const data = reverseParseJsonHack({
		name,
		policyId: policyId ?? null,
		tenantUpdatedAt: licenseGroupData.tenantUpdatedAt
	});

	const ecdh = await Crypto.Ecdh.create();
	const state = getState();
	const userVault = getUserVault(state);
	const devices = getDevicesByDeviceGroup(state, [id]);
	const params = [];
	try {
		for (const [key, device] of Object.entries(devices)) {
			const password = policy ? getAdminPasswordByPolicyId(policy.id)(state) : undefined;
			params.push({
				deviceId: device.serial,
				changeAdminPasswordTask:
					!policy || _.isNil(password)
						? undefined
						: await createChangeAdminPasswordTask({
								ecdh,
								licenseGroupId,
								groupKeys: userVault?.vault.get(licenseGroupId)?.keys,
								policy,
								device,
								password
						  })
			});
		}

		const response = await axios.patch(`/api/v1/devicegroup/${licenseGroupId}/${id}`, data);

		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('failed to update device group', 'warning'));
		} else {
			try {
				if (policy && policy.passwordConfig.passwordGenerationType === 'random' && rotatePassword) {
					await dispatch(
						policyActions.submitPasswordChange({
							policyId: policy.id,
							passwordConfig: policy.passwordConfig
						})
					);
				}

				if (policy && policy.passwordConfig.passwordGenerationType === 'manual'){
					const pwChangeResponses = await Promise.all(
						_.chunk(params, 25).map(payload => axios.patch(`/api/v1/device/${licenseGroupId}`, payload))
					);
					if (responseErrors(pwChangeResponses).length) {
						//dispatch(appActions.alert('failed to assign policy to some device groups', 'warning'));
						console.error('failed to submit manual pw changes')
					}
				}
				dispatch(appActions.alert('device group updated', 'success'));
			} catch {
				dispatch(appActions.alert('failed to update password configuration', 'warning'));
			}
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	} 
};

export const deleteDeviceGroup = (deviceGroupId: DeviceGroup['id']): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const response = await axios.delete(`/api/v1/devicegroup/${licenseGroupId}/${deviceGroupId}`);
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('failed to delete device group', 'warning'));
		} else {
			dispatch(appActions.alert('device group deleted', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const assignPolicyToDeviceGroups = (
	deviceGroupIds: DeviceGroup['id'][],
	policyId: string | undefined,
	rotatePassword = true
): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const policy = policyId ? getPolicyById(policyId)(getState()) : undefined;
	const licenseGroupData = getSelectedLicenseGroupData(getState());

	const ecdh = await Crypto.Ecdh.create();
	const state = getState();
	const userVault = getUserVault(state);
	const devices = getDevicesByDeviceGroup(state, deviceGroupIds);

	try {
		const params = [];
		for (const [key, device] of Object.entries(devices)) {
			const password = policy ? getAdminPasswordByPolicyId(policy.id)(state) : undefined;
			params.push({
				deviceId: device.serial,
				changeAdminPasswordTask:
					!policy || _.isNil(password)
						? undefined
						: await createChangeAdminPasswordTask({
								ecdh,
								licenseGroupId,
								groupKeys: userVault?.vault.get(licenseGroupId)?.keys,
								policy,
								device,
								password
						  })
			});
		}

		const responses = await Promise.all(
			_.chunk(deviceGroupIds, 25).map(chunk =>
				axios.patch(
					`/api/v1/devicegroup/${licenseGroupId}`,
					chunk.map(deviceGroupId => ({ deviceGroupId, policyId: policyId ?? null, tenantUpdatedAt: licenseGroupData.tenantUpdatedAt}))
				)
			)
		);
		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to assign policy to some device groups', 'warning'));
		} else {
			try {
				if (
					deviceGroupIds.length &&
					policy &&
					policy.passwordConfig.passwordGenerationType === 'random' &&
					rotatePassword
				) {
					await dispatch(
						policyActions.submitPasswordChange({
							policyId: policy.id,
							passwordConfig: policy.passwordConfig
						})
					);
				}
				if (deviceGroupIds.length && policy && policy.passwordConfig.passwordGenerationType === 'manual'){
					const pwChangeResponses = await Promise.all(
						_.chunk(params, 25).map(payload => axios.patch(`/api/v1/device/${licenseGroupId}`, payload))
					);
					if (responseErrors(pwChangeResponses).length) {
						//dispatch(appActions.alert('failed to assign policy to some device groups', 'warning'));
						console.error('failed to submit manual pw changes')
					}
				}
				dispatch(appActions.alert('policy assigned', 'success'));
			} catch {
				dispatch(appActions.alert('failed to update password configuration', 'warning'));
			}
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};
