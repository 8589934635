import _ from '@lodash';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import * as Actions from 'app/store/actions';
import React from 'react';
import { useDispatch, useSelector } from 'app/modules/react-redux';

function NavbarFoldedToggleButton(props) {
	const dispatch = useDispatch();
	const settings = useSelector(({ fuse }) => fuse.settings.current);

	return (
        <IconButton
            className={props.className}
            onClick={() => {
				dispatch(
					Actions.setDefaultSettings(
						_.set({}, 'layout.config.navbar.folded', !settings.layout.config.navbar.folded),
						true
					)
				);
			}}
            color="inherit"
            size="large">
			{props.children}
		</IconButton>
    );
}

NavbarFoldedToggleButton.defaultProps = {
	children: <Icon>menu</Icon>
};

export default NavbarFoldedToggleButton;
