import localForage from 'localforage';
import _ from '@lodash';
import { persistReducer } from 'redux-persist';
import type { RootState } from 'app/store';
import { DeviceGroup, DeviceGroupsState, DeviceGroupsActionTypes } from 'app/store/types';

const initialState: DeviceGroupsState = {};

const deviceGroupsReducer = (state = initialState, action: DeviceGroupsActionTypes) => {
	switch (action.type) {
		case 'GET_LICENSE_GROUP_DATA_SUCCESS': {
			const {
				licenseGroupId,
				data: { deviceGroups }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					byId: deviceGroups
				}
			};
		}
		case 'LOGGED_OUT_USER':
		case 'PURGE_STATE':
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'deviceGroups',
		storage: localForage
	},
	deviceGroupsReducer
);

// Selectors
export const getDeviceGroups = ({ app: { selectedLicenseGroupId }, deviceGroups }: RootState) =>
	Object.values(deviceGroups[selectedLicenseGroupId].byId);

export const getDeviceGroupsById = ({ app: { selectedLicenseGroupId }, deviceGroups }: RootState) =>
	deviceGroups[selectedLicenseGroupId].byId;

export const getDeviceGroupById = (deviceGroupId: DeviceGroup['id']) => (state: RootState) =>
	getDeviceGroupsById(state)[deviceGroupId];
