import axios from 'app/client';
import * as MessageActions from 'app/store/actions/fuse/message.actions';
import { AppThunk } from 'app/store';
import { getSelectedLicenseGroupData } from 'app/store/reducers';
import { PublicId } from 'app/store/types';

export const setSelectedLicenseGroup = (licenseGroupId: string | undefined) => {
	return {
		type: 'SET_SELECTED_LICENSE_GROUP',
		payload: {
			selectedLicenseGroupId: licenseGroupId
		}
	};
};

export const setUsersFilterText = (text: string) => {
	return {
		type: 'SET_USERS_FILTER_TEXT',
		payload: {
			usersFilterText: text
		}
	};
};

export const setDevicesFilterText = (text: string) => {
	return {
		type: 'SET_DEVICES_FILTER_TEXT',
		payload: {
			devicesFilterText: text
		}
	};
};

export const setPoliciesFilterText = (text: string) => {
	return {
		type: 'SET_POLICIES_FILTER_TEXT',
		payload: {
			policiesFilterText: text
		}
	};
};

export const setLogsFilterText = (text: string) => {
	return {
		type: 'SET_LOGS_FILTER_TEXT',
		payload: {
			logsFilterText: text
		}
	};
};

export const hideNavbar = (hide: boolean) => {
	return {
		type: 'HIDE_NAVBAR',
		payload: {
			hide
		}
	};
};

// alerts
type TAlert = 'success' | 'error' | 'info' | 'warning';
type TOptions = {
	autoHideDuration?: number;
	anchorOrigin?: {
		vertical: 'top' | 'bottom';
		horizontal: 'left' | 'center' | 'right';
	};
};

// DEV NOTE::do NOT pass a translation directly to `alert` - `alert` internally translates - instead add a translation to the `alert.json` namespace and just pass the key name.
export const alert = (message: string, type?: TAlert, options: TOptions = {}): AppThunk => dispatch => {
	const variant = type === 'success' ? undefined : type; // use regular alert look for success messages for now

	const defaultOptions = {
		autoHideDuration: 4500,
		anchorOrigin: {
			vertical: 'bottom', // top bottom
			horizontal: 'left' // left center right
		}
	};
	// DEV NOTE::this function is ultimately calling `FuseMessage` inside `@fuse/core/FuseMessage/FuseMessage.js`
	dispatch(
		MessageActions.showMessage({
			message,
			variant, // success error info warning null
			...defaultOptions,
			...options
		})
	);
};

export const acknowledgeCookieNagbar = () => {
	return {
		type: 'ACKNOWLEDGE_COOKIE_NAGBAR'
	};
};

export const toggleCookieSettingsDialog = (showDialog: boolean) => {
	return {
		type: 'TOGGLE_COOKIE_SETTINGS_DIALOG',
		payload: {
			showCookieSettings: showDialog
		}
	};
};

export const hideChangelogBanner = () => {
	return {
		type: 'HIDE_CHANGELOG_BANNER'
	};
};

export const hideExpiredBanner = () => {
	return {
		type: 'HIDE_EXPIRED_BANNER'
	};
};

export const hideExpiredScreen = () => {
	return {
		type: 'HIDE_EXPIRED_SCREEN'
	};
};

// TODO::change all alerts to be worded correctly
// error handler
export const handleError = (error: any): AppThunk => dispatch => {
	console.error(error);

	// don't display error alert if the request was aborted (assumed to have been purposefully aborted)
	if (error.code === 'ECONNABORTED') {
		return;
	}

	if (error.response) {
		console.error(error.response.data);

		// if user no longer authenticated
		if (error.response.status === 401) {
			// the actions after this trigger a page change but this might help for the second before redirect
			dispatch(alert('user not authenticated', 'error'));
			// this will trigger a login if on a license group page
			dispatch({
				type: 'GET_PROFILE_SUCCESS',
				payload: {
					data: undefined
				}
			});
			dispatch({
				type: 'LOGGED_OUT_USER'
			});
			return;
		}
		// if (error.data && error.data.message) {
		// 	return dispatch(alert(error.data.message, 'error'));
		// }
		if (error.response.status === 403) {
			dispatch(alert('permission denied', 'error'));
			return;
		}
	}
	dispatch(alert('something went wrong', 'error'));
};

// export const errorLog = err => async dispatch => {
// 	try {
// 		await axios.post('/api/error-log', { error: err });
// 	} catch (error) {
// 		dispatch(handleError(error));
// 	}
// };

export const clearModifiedLicenseGroupPricing = (): AppThunk => async dispatch => {
	dispatch({
		type: 'GET_MODIFIED_LICENSE_GROUP_PRICING_SUCCESS',
		payload: {
			currentPrice: undefined,
			modifiedPrice: undefined
		}
	});
};

export const getModifiedLicenseGroupPricing = ({
	newPublicId,
	newCapacity
}: {
	newPublicId: PublicId;
	newCapacity: number;
}): AppThunk => async (dispatch, getState) => {
	const {
		id: licenseGroupId,
		catalogPublicId: currentPublicId,
		capacity: currentCapacity
	} = getSelectedLicenseGroupData(getState());

	try {
		const [
			{
				data: { total: currentTotal, currencyCode: currentCurrencyCode }
			},
			{
				data: { total: modifiedTotal, currencyCode: modifiedCurrencyCode }
			}
		] = await Promise.all([
			axios.get(
				`/api/mp/catalog/pricing/${currentPublicId}?license_group=${licenseGroupId}&quantity=${currentCapacity}`
			),
			axios.get(`/api/mp/catalog/pricing/${newPublicId}?license_group=${licenseGroupId}&quantity=${newCapacity}`)
		]);
		dispatch({
			type: 'GET_MODIFIED_LICENSE_GROUP_PRICING_SUCCESS',
			payload: {
				currentPrice: {
					total: currentTotal,
					currency: currentCurrencyCode
				},
				modifiedPrice: {
					total: modifiedTotal,
					currency: modifiedCurrencyCode
				}
			}
		});
	} catch (error) {
		dispatch(handleError(error));
	}
};
