import React from 'react';

const DashboardPageConfig = {
	settings: {
		layout: {
			config: {
				adminPage: true
			}
		}
	},
	routes: [
		{
			path: '/admin/dashboard',
			exact: true,
			component: React.lazy(() => import('./DashboardPage'))
		}
	]
};

export default DashboardPageConfig;
