import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import _ from '@lodash';
import type { RootState } from 'app/store';
import { MpDevicesState, MpDevicesActionTypes } from 'app/store/types';

const initialState: MpDevicesState = {
	list: undefined
};

const mpDevicesReducer = (state = initialState, action: MpDevicesActionTypes) => {
	switch (action.type) {
		case 'GET_MP_DEVICES_SUCCESS': {
			return {
				...state,
				list: action.payload.list
			};
		}
		case 'LOGGED_OUT_USER':
		case 'PURGE_STATE':
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'mpDevices',
		storage: localForage,
		whitelist: [] // get list every site revisit
	},
	mpDevicesReducer
);

// Selectors
export const getMpDevices = ({ mpDevices }: RootState) => mpDevices.list;
export const getMpDevicesById = ({ mpDevices }: RootState) => _.keyBy(mpDevices.list, 'serial');
